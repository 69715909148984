import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';

import type { ReportDefinitionFormData } from './definitionSchema';
import { defaultValues, ReportDefinitionFormSchema } from './definitionSchema';
import { ReportDefinitionFormFields } from './ReportDefinitionFormFields';

export type Props = {
  onSave: (data: ReportDefinitionFormData) => Promise<void>;
  values?: ReportDefinitionFormData;
};

export const ReportDefinitionForm: FC<Props> = ({ onSave, values }) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'reports' });

  return (
    // TODO: use non customisable form
    <CustomisableFormContext
      defaultValues={defaultValues}
      i18n={{
        entity_name: t('entity_name'),
      }}
      values={values}
      onSave={onSave}
      schema={ReportDefinitionFormSchema}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      formId={''}
    >
      <ReportDefinitionFormFields />
    </CustomisableFormContext>
  );
};
