import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';
import type { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useInsertIssueOnlyMutation,
} from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';
import { reportAnIssueSuccessUrl } from '@/utils/urls';

import IssueForm from '../update/forms/IssueForm';
import IssueTypeSelector from './IssueTypeSelector';

const Page: FC = () => {
  const { updateFiles } = useFileUpdate();
  const navigate = useNavigate();
  const [issueType, setIssueType] = useState<ParentIssueTypes>(
    Parent_Type_Enum.Issue
  );
  const issueMapping = IssueTypeMapping[issueType];
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: issueMapping.taxonomy,
  });
  const goldenCharterIssuesEnabled = useIsFeatureVisibleToOrg('issue-gc');
  const allicaIssuesEnabled = useIsFeatureVisibleToOrg('issue-allica');
  const additionalIssueVariants =
    goldenCharterIssuesEnabled || allicaIssuesEnabled;
  const defaultTitle = st('report_issue_title');

  const [insertIssueOnly] = useInsertIssueOnlyMutation({});

  const onSave = async (data: IssueFormDataFields) => {
    const { files, newFiles } = data;
    const id = crypto.randomUUID();
    await insertIssueOnly({
      variables: {
        ...data,
        Id: id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        Tags: tagInsertInputBuilder(data.TagTypeIds),
        Departments: departmentInsertInputBuilder(data.DepartmentTypeIds),
        ...ownerAndContributorInsertFields(data),
      },
    });

    await updateFiles({
      parentType: Parent_Type_Enum.Issue,
      parentId: id,
      newFiles,
      originalFiles: [],
      selectedFiles: files,
    });
    navigate(reportAnIssueSuccessUrl());
  };

  const beforeIssueSlot = (
    <>
      <div className="pb-5">
        <IssueTypeSelector
          testId="IssueType"
          value={issueType}
          readOnly={false}
          onChange={(val) => {
            setIssueType(val);
          }}
        />
      </div>
    </>
  );

  return (
    <PageLayout title={defaultTitle} actions={<></>}>
      <Container header={<Header variant="h2">{t('details')}</Header>}>
        <IssueForm
          onSave={onSave}
          issueType={issueType}
          renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
          beforeFieldsSlot={additionalIssueVariants ? beforeIssueSlot : <></>}
        />
      </Container>
    </PageLayout>
  );
};

export default Page;
