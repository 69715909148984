import type {
  JsonSchema,
  Labelable,
  Scopable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';
import { RuleEffect } from '@jsonforms/core';
import type { SchemaBasedCondition } from '@jsonforms/core/src/models/uischema';

import type { QuestionData } from '../types';
import { FieldOptionType } from '../types';
import { useFieldTypeOptions } from '../useFieldTypeOptions';

export const defaultQuestionData: QuestionData = {
  questionTitle: '',
  placeholder: '',
  description: '',
  fieldType: FieldOptionType.Text,
  selectOptions: [],
  isPropertyRequired: true,
  allowAttachments: false,
  files: [],
};

export const useQuestionSchema = (): JsonSchema => {
  const fieldTypeOptions = useFieldTypeOptions();

  return {
    type: 'object',
    properties: {
      questionTitle: { type: 'string', minLength: 1 },
      placeholder: { type: 'string' },
      description: { type: 'string' },
      fieldType: {
        type: 'string',
        oneOf: Object.entries(fieldTypeOptions).map(([_, option]) => {
          return { const: option.value, title: option.label };
        }),
        minLength: 1,
      },
      isPropertyRequired: { type: 'boolean' },
      allowAttachments: { type: 'boolean' },
      selectOptions: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            value: {
              type: 'string',
              minLength: 1,
            },
            generatedId: {
              type: 'string',
              minLength: 1,
            },
          },
        },
        minLength: 1,
      },
    },
    required: [
      'questionTitle',
      'fieldType',
      'isPropertyRequired',
      'allowAttachments',
      'selectOptions',
    ],
  };
};

interface UISchemeElementWithSchemaBasedRules extends UISchemaElement {
  rule?: {
    effect: RuleEffect;
    condition: SchemaBasedCondition;
  };
}

interface VerticalLayoutWithLabelables extends VerticalLayout {
  elements: (UISchemeElementWithSchemaBasedRules & Labelable & Scopable)[];
}

export const questionUISchema: VerticalLayoutWithLabelables = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      label: 'Question title',
      scope: '#/properties/questionTitle',
      options: {
        placeholder: 'Enter question title here...',
      },
    },
    {
      type: 'Control',
      label: 'Placeholder text',
      scope: '#/properties/placeholder',
      options: {
        placeholder: 'Enter placeholder text here...',
      },
      rule: {
        effect: RuleEffect.DISABLE,
        condition: {
          scope: '#/properties/fieldType',
          schema: { const: 'date' },
        },
      },
    },
    {
      type: 'Control',
      label: 'Guidance',
      scope: '#/properties/description',
      options: {
        placeholder: 'Enter guidance here...',
        description: 'This is an example of what the guidance will look like',
      },
    },
    {
      type: 'Control',
      label: 'Field Type',
      scope: '#/properties/fieldType',
    },
    {
      type: 'Control',
      label: 'Options',
      scope: '#/properties/selectOptions',
      rule: {
        effect: RuleEffect.HIDE,
        condition: {
          scope: '#/properties/fieldType',
          schema: {
            not: {
              enum: [FieldOptionType.Dropdown, FieldOptionType.Multiselect],
            },
          },
        },
      },
      options: {
        placeholder: 'Enter option value',
      },
    },
    {
      type: 'Control',
      label: 'Response required',
      scope: '#/properties/isPropertyRequired',
    },
    {
      type: 'Control',
      label: 'Allow attachments',
      scope: '#/properties/allowAttachments',
    },
  ],
};
