import Alert from '@cloudscape-design/components/alert';
import type { FC } from 'react';
import type { FieldErrors, FieldValues } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import { HasuraErrorCodes } from '@/utils/graphqlUtils';

type Props<T extends FieldValues> = {
  errors: FieldErrors<T>;
};

export function FormErrors<T extends FieldValues>({ errors }: Props<T>) {
  const hasErrors = Object.keys(errors).length > 0;
  let errorMessage = 'This form has errors';
  if (errors?.global?.message) {
    errorMessage = errors.global.message as string;
  }
  if (errors.global?.type) {
    switch (errors.global.type) {
      case HasuraErrorCodes.PermissionError:
        errorMessage = 'Permission denied';
        break;
      case HasuraErrorCodes.UnexpectedError:
      case HasuraErrorCodes.ConstraintError:
        errorMessage = 'Unexpected error';
        break;
      case HasuraErrorCodes.ValidationFailed:
        errorMessage = 'Validation failed';
        break;
      default:
        if (errors.global.message) {
          errorMessage = errors.global.message as string;
        }
    }
  }

  return hasErrors ? (
    <Alert statusIconAriaLabel="Error" type="error" header={errorMessage} />
  ) : (
    <></>
  );
}

const WrappedFormErrors: FC = () => {
  const {
    formState: { errors },
  } = useFormContext();

  return <FormErrors errors={errors} />;
};

export default WrappedFormErrors;
