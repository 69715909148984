import type { TabsProps } from '@cloudscape-design/components/tabs';
import { useTranslation } from 'react-i18next';

import { filterEmptyTabs } from '@/utils/tabUtils';
import { enterpriseRiskDetailsUrl } from '@/utils/urls';

import RiskCreateTab from '../create/tabs/CreateTab';
import type { EnterpriseRiskFields } from '../types';
import DetailsTab from './tabs/DetailsTab';

export const useTabs = (
  enterpriseRisk: EnterpriseRiskFields | undefined = undefined
) => {
  const { t } = useTranslation([]);
  const detailsOnly = !enterpriseRisk;

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: detailsOnly ? <RiskCreateTab /> : <DetailsTab />,
      href: detailsOnly
        ? undefined
        : enterpriseRiskDetailsUrl(enterpriseRisk.Id),
    },
  ];

  return filterEmptyTabs(tabs);
};
