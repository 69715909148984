import { useCollection } from '@cloudscape-design/collection-hooks';
import Grid from '@cloudscape-design/components/grid';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import PageFilterContainer from '@/components/PageFilterContainer/PageFilterContainer';
import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import {
  Obligation_Type_Enum,
  useGetLatestObligationAssessmentResultsQuery,
  useGetObligationsQuery,
} from '@/generated/graphql';
import { useAddCustomAttributes } from '@/utils/table/hooks/useAddCustomAttributes';
import { useCreateFilterOptions } from '@/utils/table/hooks/useCreateFilterOptions';
import { useCreateFilterProperties } from '@/utils/table/hooks/useCreateFilterProperties';
import { addObligationUrl } from '@/utils/urls';

import { useGetFieldConfig } from '../config';
import { useGetLabelledFields } from '../useLabelledFields';
import Type from './Type';

const Page: FC = () => {
  const { t } = useTranslation();
  const title = t('obligations.dashboard_title');

  const [selectedChapterParentId, setSelectedChapterParentId] =
    useState<string>();
  const [selectedRuleParentId, setSelectedRuleParentId] = useState<string>();
  const { addNotification } = useNotifications();
  const { data, loading: loadingObligations } = useGetObligationsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const { data: latestAssessmentResults, loading: loadingAssessments } =
    useGetLatestObligationAssessmentResultsQuery({
      fetchPolicy: 'no-cache',
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const labelledFields = useGetLabelledFields(
    data?.obligation,
    latestAssessmentResults?.obligation_assessment_result
  );
  const fields = useGetFieldConfig();
  const { tableFields, tableData } = useAddCustomAttributes({
    fields,
    data: labelledFields,
    customAttributeSchema:
      data?.form_configuration?.[0]?.customAttributeSchema ?? null,
    useRelativeDates: false,
  });
  const filteringProperties = useCreateFilterProperties(tableFields);

  const { items, propertyFilterProps } = useCollection(tableData, {
    propertyFiltering: {
      filteringProperties,
    },
    selection: {},
  });

  const fixedFilterOptions = useCreateFilterOptions(
    tableFields,
    tableData,
    propertyFilterProps.filteringOptions
  );

  const onSelectItem = (obligationId: string, type: Obligation_Type_Enum) => {
    switch (type) {
      case Obligation_Type_Enum.Standard:
        setSelectedChapterParentId(obligationId);
        setSelectedRuleParentId(undefined);
        break;
      case Obligation_Type_Enum.Chapter:
        setSelectedRuleParentId(obligationId);
        break;
    }
  };

  return (
    <PageLayout
      helpTranslationKey="obligations.dashboardHelp"
      title={title}
      pageTitle={title}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Permission
            permission="insert:obligation"
            canHaveAccessAsContributor={true}
          >
            <Button variant="primary" href={addObligationUrl()}>
              {t('obligations.create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <PageFilterContainer>
        <div className="flex w-full space-x-2">
          <div className="grow">
            <PropertyFilterPanel
              {...propertyFilterProps}
              filteringOptions={fixedFilterOptions}
              i18nStrings={defaultPropertyFilterI18nStrings}
              virtualScroll={true}
            />
          </div>
        </div>
      </PageFilterContainer>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
        <Type
          loading={loadingObligations || loadingAssessments}
          obligations={items}
          type={Obligation_Type_Enum.Standard}
          onSelectAction={onSelectItem}
        />
        <Type
          loading={loadingObligations || loadingAssessments}
          obligations={items}
          type={Obligation_Type_Enum.Chapter}
          selectedId={selectedChapterParentId}
          onSelectAction={onSelectItem}
        />
        <Type
          loading={loadingObligations || loadingAssessments}
          obligations={items}
          type={Obligation_Type_Enum.Rule}
          selectedId={selectedRuleParentId}
          onSelectAction={onSelectItem}
        />
      </Grid>
    </PageLayout>
  );
};

export default Page;
