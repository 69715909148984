import type { TableProps } from '@cloudscape-design/components/table';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import type { GetReportingDataQuery } from '@/generated/graphql';
import { useRatingLazy } from '@/hooks/useRatingLazy';
import { toLocalDate } from '@/utils/dateUtils';

import type { ReportDefinitionFormData } from './definitionSchema';
import { getAllowedFields } from './utils';

const ReportTable: FC<{
  definition: ReportDefinitionFormData | undefined;
  items: GetReportingDataQuery['reportingData'];
}> = ({ definition, items }) => {
  const { getByValue } = useRatingLazy();
  const allowedFields = getAllowedFields(definition?.dataSource);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columnDefinitions: readonly TableProps.ColumnDefinition<any>[] =
    definition?.fields.map((field, index) => {
      const allowedField = allowedFields.find(
        (af) =>
          af.fieldId === field.fieldId &&
          af.dataSourceIndex === field.dataSourceIndex
      );

      return {
        header: allowedField?.label ?? '-',
        cell: (item) => {
          const fieldDef = allowedField?.fieldDef;
          const key = `f${index}`;
          const value = item[key];
          switch (fieldDef?.displayType) {
            case 'date':
              return toLocalDate(value);
            case 'options':
              return fieldDef
                .getOptions()
                .find((o) => o.value === String(value))?.label;
            case 'rating':
              return (
                <SimpleRatingBadge
                  rating={getByValue(fieldDef.ratingKey, value)}
                />
              );
          }

          return value;
        },
      };
    }) ?? [];

  return (
    <Table
      loading={false}
      items={items ?? []}
      columnDefinitions={columnDefinitions}
      empty={'No records found'}
    />
  );
};

export default ReportTable;
