import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import {
  namedOperations,
  useGetEnterpriseRiskByIdQuery,
  useUpdateEnterpriseRiskMutation,
} from 'src/generated/graphql';
import RiskForm from 'src/pages/risks/forms/RiskForm';
import type { RiskFormDataFields } from 'src/pages/risks/forms/riskSchema';
import { defaultValues } from 'src/pages/risks/forms/riskSchema';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import { evictField } from '@/utils/graphqlUtils';

const Tab: FC = () => {
  useI18NSummaryHelpContent('enterpriseRisks.help');
  const id = useGetGuidParam('id');
  const navigate = useNavigate();
  const { data, error } = useGetEnterpriseRiskByIdQuery({
    variables: { Id: id },
    fetchPolicy: 'no-cache',
  });

  if (error) {
    throw error;
  }

  const enterpriseRisk = data?.enterprise_risk[0];

  const canEditEnterpriseRisk = useHasPermission('update:enterprise_risk');

  const [updateEnterpriseRisk] = useUpdateEnterpriseRiskMutation({
    update: (cache) => {
      evictField(cache, 'enterprise_risk');
    },
    refetchQueries: [
      namedOperations.Query.getEnterpriseRiskById,
      namedOperations.Query.getEnterpriseRisks,
    ],
  });

  const onSave = async (riskFormData: RiskFormDataFields) => {
    if (!id) {
      throw new Error('Missing enterprise risk');
    }
    await updateEnterpriseRisk({
      variables: {
        Id: id,
        Tier: riskFormData.Tier,
        Title: riskFormData.Title,
        Description: riskFormData.Description,
        Treatment: riskFormData.Treatment,
        CustomAttributeData: riskFormData.CustomAttributeData ?? null,
        ParentId: riskFormData.ParentRiskId ?? null,
      },
    });
  };
  const onDismiss = () => navigate(-1);

  return (
    <>
      <RiskForm
        enterpriseRisk
        onSave={onSave}
        onDismiss={onDismiss}
        values={{
          ...defaultValues,
          ...enterpriseRisk,
          Description: enterpriseRisk?.Description ?? '',
          Tier: enterpriseRisk?.Tier as 1 | 2 | 3,
        }}
        readOnly={!canEditEnterpriseRisk}
        riskId={id}
      />
    </>
  );
};

export default Tab;
