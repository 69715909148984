import _ from 'lodash';
import { useMemo } from 'react';
import {
  getAllContributorsCellValue,
  getAllOwnersCellValue,
} from 'src/rbac/contributorHelper';

import { Parent_Type_Enum } from '@/generated/graphql';
import useEntityInfo from '@/hooks/getEntityInfo';
import { useRating } from '@/hooks/use-rating';
import { getFriendlyId } from '@/utils/friendlyId';

import {
  conformanceRatingFromResults,
  getConformanceTrendRating,
} from './calculateConformanceRating';
import { latestResultValueFromData } from './latestResultValueFromData';
import {
  parentTitleFromLinkedParents,
  parentTypesFromLinkedParents,
} from './parentHelpers';
import type { IndicatorFlatFields, IndicatorTableFields } from './types';

export const useLabelledFields = (
  records: IndicatorFlatFields[] | undefined
) => {
  const { getLabel: getConformanceLabel } = useRating(
    'indicator_conformance_status'
  );
  const { getLabel: getTestFreqLabel } = useRating('frequency');
  const getEntityInfo = useEntityInfo();
  const { getLabel: trendGetLabel } = useRating('indicator_conformance_trend');

  return useMemo<IndicatorTableFields[] | undefined>(() => {
    return records?.map((d) => {
      const conformanceTrendValue = getConformanceTrendRating(
        d,
        d.latestResults
      );

      return {
        ...d,
        ParentTitle: parentTitleFromLinkedParents(d, getEntityInfo) || '-',
        ParentType:
          (parentTypesFromLinkedParents(d, getEntityInfo) || []).join(', ') ||
          '-',
        CreatedByUserName: d.createdBy?.FriendlyName || '-',
        ModifiedByUserName: d.modifiedBy?.FriendlyName || '-',
        LatestResultLabelled: latestResultValueFromData(d) || '-',
        LatestResultDateLabelled: d.latestResults[0]?.ResultDate || '-',
        Conformance: conformanceRatingFromResults(d),
        ConformanceLabelled: getConformanceLabel(
          conformanceRatingFromResults(d)
        ),
        ConformanceTrendValue: conformanceTrendValue,
        ConformanceTrend: trendGetLabel(conformanceTrendValue) || 'Static',
        TestFrequencyLabelled: getTestFreqLabel(d.schedule?.Frequency) || '-',
        SequentialIdLabel: d.SequentialId
          ? getFriendlyId(Parent_Type_Enum.Indicator, d.SequentialId)
          : '',
        allOwners: getAllOwnersCellValue(d),
        allContributors: getAllContributorsCellValue(d),
        NextTestDate: d.scheduleState?.DueDate ?? '-',
        NextTestOverdueDate: d.scheduleState?.OverdueDate ?? '-',
      };
    });
  }, [
    records,
    getEntityInfo,
    getConformanceLabel,
    trendGetLabel,
    getTestFreqLabel,
  ]);
};
