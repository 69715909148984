import dayjs from 'dayjs';

import BadgeList from '@/components/BadgeList';
import {
  dateRangeFilterOperators,
  defaultDateFilterOperators,
} from '@/components/DateTimeFilter/dateFilterOperator';
import { getCustomAttributeCollections } from '@/components/Form/CustomAttributes/CustomAttributeCollections';
import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import { FieldType } from '@/components/Form/CustomAttributes/EditFields/NewFieldSchema';
import Link from '@/components/Link';
import type { JSONObject } from '@/types/types';

import { EMPTY_VALUE } from '../../collectionUtils';
import { toLocalDate } from '../../dateUtils';
import type { FieldConfig, TableRecord } from '../types';

export const getCustomAttributeDataForRecord = <T extends TableRecord>(
  fields: Record<keyof T, FieldConfig<T>>,
  record: T
) => {
  return Object.entries(fields).reduce((acc, [key, field]) => {
    if (field.custom) {
      return {
        ...acc,
        [key]: field.customFieldValue(record),
      };
    }

    return acc;
  }, {});
};

function convertSchemaToFieldConfig<
  T extends {
    CustomAttributeData: JSONObject;
  },
>({
  schemas,
  enableRelativeDates,
}: {
  schemas: CustomAttributeFields | undefined;
  enableRelativeDates: boolean;
}): { [key: string]: FieldConfig<T> } {
  const config: { [key: string]: FieldConfig<T> } = {};
  if (!schemas?.Schema || !schemas?.UiSchema) {
    return config;
  }
  // render column collections from schemas.
  const collections = getCustomAttributeCollections(
    schemas?.Schema || {},
    schemas?.UiSchema || {}
  );

  // map collections to config using type.
  collections.forEach(({ label, path, type }) => {
    const matchToField = (data: JSONObject | null) =>
      data ? (data[path] as string) || EMPTY_VALUE : EMPTY_VALUE;
    const matchToArrayField = (data: JSONObject | null) =>
      data ? (data[path] as string[]) || [] : [];
    // Initial config for all types
    const basicConfig: FieldConfig<T> = {
      header: label,
      custom: true,
      customFieldValue: (item) => {
        return matchToField(item.CustomAttributeData);
      },
      exportVal: (item) => {
        return matchToField(item.CustomAttributeData);
      },
    };

    // Add specific config for each type
    switch (type) {
      case FieldType.Text:
      case FieldType.Textarea:
      case FieldType.Select:
        config[path] = {
          ...basicConfig,
          cell: (item) => matchToField(item.CustomAttributeData),
        };

        break;

      case FieldType.MultiSelect:
        config[path] = {
          ...basicConfig,
          cell: (item) => {
            return (
              <BadgeList badges={matchToArrayField(item.CustomAttributeData)} />
            );
          },
        };

        break;

      case FieldType.Date:
        config[path] = {
          ...basicConfig,
          cell: (item) => toLocalDate(matchToField(item.CustomAttributeData)),
          filterOptions: {
            filteringProperties: {
              operators: enableRelativeDates
                ? dateRangeFilterOperators
                : defaultDateFilterOperators,
            },
          },
          exportVal: (item) =>
            matchToField(item.CustomAttributeData) !== EMPTY_VALUE
              ? dayjs(String(matchToField(item.CustomAttributeData))).format(
                  'DD/MM/YYYY HH:mm'
                )
              : '',
        };

        break;

      case FieldType.Link:
        config[path] = {
          ...basicConfig,
          cell: (item) =>
            matchToField(item.CustomAttributeData) !== EMPTY_VALUE ? (
              <Link
                href={matchToField(item.CustomAttributeData)}
                target="_blank"
              >
                {matchToField(item.CustomAttributeData)}
              </Link>
            ) : (
              EMPTY_VALUE
            ),
        };

        break;
    }
  });

  return config;
}

export const convertSchemasToFieldConfigs = ({
  customAttributeSchemas,
  enableRelativeDates,
}: {
  customAttributeSchemas: CustomAttributeFields[];
  enableRelativeDates: boolean;
}): ReturnType<typeof convertSchemaToFieldConfig> => {
  return customAttributeSchemas.reduce((configs, schemas) => {
    return {
      ...configs,
      ...convertSchemaToFieldConfig({ schemas, enableRelativeDates }),
    };
  }, {});
};
