import type { Parent_Type_Enum } from '../generated/graphql';
import { useDeleteRelationFileByIdMutation } from '../generated/graphql';
import { useFileUpload } from './useFileUpload';

type UseFileUpdateOptions = {
  // TODO: investigate if we can delete this (here as well as in the relation_file table) now that we have the node table with the object type on it
  parentType: Parent_Type_Enum;
  parentId: string;
  // New files are the files that are being added to the parent.
  newFiles?: Array<File & { meta?: { [key: string]: string } }>;
  // Original files are the files that were already associated with the parent.
  originalFiles?: { file?: { Id: string | undefined } | null }[];
  // Selected files is a subset of the orginal files that we intend to keep.
  // Any files from the original files not listed will be deleted.
  selectedFiles?: { file?: { Id: string | undefined } | null }[];
};

type UseMultiParentFileUpdateOptions = {
  parentType: Parent_Type_Enum;
  parentIds: string[];
  newFiles?: Array<File & { meta?: { [key: string]: string } }>;
  originalFiles?: { file?: { Id: string | undefined } | null }[];
  selectedFiles?: { file?: { Id: string | undefined } | null }[];
};

const useUpdateFiles = () => {
  const [deleteFilesById] = useDeleteRelationFileByIdMutation({});
  const uploadFiles = useFileUpload();

  return async ({
    parentType,
    parentIds,
    newFiles,
    originalFiles,
    selectedFiles,
  }: UseMultiParentFileUpdateOptions): Promise<void> => {
    const removedFileIds = getRemovedFilesIds(
      originalFiles || [],
      selectedFiles || []
    );
    if (newFiles && newFiles.length > 0) {
      await uploadFiles(parentType, parentIds, newFiles);
    }
    if (removedFileIds.length > 0) {
      await deleteFilesById({
        variables: {
          parentIds,
          fileIds: removedFileIds,
        },
      });
    }
  };
};

export const useFileUpdate = () => {
  const updateFiles = useUpdateFiles();

  return {
    updateFiles: async ({
      parentType,
      parentId,
      newFiles,
      originalFiles,
      selectedFiles,
    }: UseFileUpdateOptions): Promise<void> =>
      await updateFiles({
        parentType,
        parentIds: [parentId],
        newFiles,
        originalFiles,
        selectedFiles,
      }),
    error: '',
  };
};

export const useMultiParentFileUpdate = () => {
  const updateFiles = useUpdateFiles();

  return {
    updateFiles: async ({
      parentType,
      parentIds,
      newFiles,
      originalFiles,
      selectedFiles,
    }: UseMultiParentFileUpdateOptions): Promise<void> =>
      await updateFiles({
        parentType,
        parentIds,
        newFiles,
        originalFiles,
        selectedFiles,
      }),
    error: '',
  };
};

const getRemovedFilesIds = (
  originalFiles: { file?: { Id: string | undefined } | null }[],
  files: { file?: { Id: string | undefined } | null }[]
): string[] => {
  return (
    originalFiles
      .filter((f) => !files.find((x) => x.file?.Id === f.file?.Id))
      .map((f) => f.file!.Id!) || []
  );
};
