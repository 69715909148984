import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { RatingKeys, RatingOption } from 'src/ratings/ratings';

export const useRatingLazy = () => {
  const { t } = useTranslation('ratings');

  const getOptions = useCallback(
    (ratingKey: RatingKeys): RatingOption[] =>
      ratingKey
        ? t(ratingKey, {
            returnObjects: true,
          })
        : [],
    [t]
  );

  const getByValue = useCallback(
    (
      ratingKey: RatingKeys,
      value: string | number
    ): RatingOption | undefined => {
      const options = getOptions(ratingKey);

      return options.find((r) => r.value === value);
    },
    [getOptions]
  );

  return {
    getByValue,
    getOptions,
  };
};
