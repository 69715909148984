import { useFormBuilderStore } from './useFormBuilderStore';

export const excludeRequiredPropertyFromRequiredList = ({
  propertyIds,
  uiSchemaId,
}: {
  propertyIds: string[];
  uiSchemaId: string;
}): string[] => {
  const schema = useFormBuilderStore.getState().schema;

  return (
    schema.required?.filter((fieldId) => {
      if (!propertyIds.includes(fieldId)) {
        // This ensures any required fields that are no longer in the schema are removed from the required list...
        return false;
      }

      // ...or filters out the field id that belongs to the deleted section
      return fieldId !== uiSchemaId;
    }) || []
  );
};
