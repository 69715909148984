import type { actions as sharedDataset } from '@risksmart-app/shared/reporting/datasets/actions';

import type { FrontEndDataSource } from './types';

export const actions: FrontEndDataSource<typeof sharedDataset> = {
  label: 'Actions',
  fields: {
    title: { displayType: 'text', label: 'Title' },
    id: { displayType: 'text', label: 'Id' },
    dateDue: { displayType: 'date', label: 'Date due' },
    priority: {
      displayType: 'rating',
      ratingKey: 'priority',
      label: 'Priority',
    },
  },
};
