import { DataSourceType } from '../../reporting/schema';
import { actions } from './actions';
import { controls } from './controls';
import { issues } from './issues';
import { risks } from './risks';
import { SharedDatasets } from './types';

export const sharedDatasets: SharedDatasets = {
  risks,
  actions,
  issues,
  controls,
};

/**
 * Create lookup of data source children
 */
const createChildLookup = (): Map<DataSourceType, Set<DataSourceType>> => {
  const lookup = new Map<DataSourceType, Set<DataSourceType>>();
  for (const type in sharedDatasets) {
    const childDatasource = type as keyof typeof sharedDatasets;
    const dataset = sharedDatasets[type as keyof typeof sharedDatasets];
    dataset.parentTypes.forEach((parentType) => {
      if (!lookup.has(parentType)) {
        lookup.set(parentType, new Set<DataSourceType>());
      }
      lookup.get(parentType)?.add(childDatasource);
    });
  }

  return lookup;
};
const childDataSourceLookup = createChildLookup();

/**
 * Retrieve possible children of a data source
 * @param dataSource
 * @returns
 */
export const getChildDataSources = (
  dataSource: DataSourceType
): Set<DataSourceType> => childDataSourceLookup.get(dataSource) ?? new Set();
