import FormField from '@cloudscape-design/components/form-field';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DateTimeForm } from '@/components/DateTimeFilter/DateTimeForm';
import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import { useRatingLazy } from '@/hooks/useRatingLazy';
import { toLocalDate } from '@/utils/dateUtils';

import type { Filters } from './definitionSchema';
import SelectFilter from './filters/SelectFilter';
import type { AllowedField } from './utils';

const ReportPropertyFilterPanel: FC<{
  allFields: AllowedField[];
}> = ({ allFields }) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'reports.fields' });
  const { watch, setValue } = useFormContext();
  const filters = watch('filters');
  const { getByValue, getOptions } = useRatingLazy();

  return (
    <FormField label={t('filters')}>
      <PropertyFilterPanel
        hideOperations={true}
        disableFreeTextFiltering={true}
        virtualScroll={true}
        expandToViewport={true}
        i18nStrings={defaultPropertyFilterI18nStrings}
        query={filters}
        filteringOptions={allFields.flatMap((a) => {
          const fieldDef = a.fieldDef;
          if (fieldDef.displayType === 'options') {
            return fieldDef
              .getOptions()
              .map((o) => ({ ...o, propertyKey: a.value }));
          }

          return [];
        })}
        filteringProperties={
          allFields.map((f) => {
            const fieldDef = f.fieldDef;
            switch (fieldDef.displayType) {
              case 'date':
                return {
                  key: f.value,
                  groupValuesLabel: '',
                  propertyLabel: f.label,
                  operators: ['<', '>'].map((op) => ({
                    operator: op,
                    form: DateTimeForm,
                    format: toLocalDate,
                  })),
                  defaultOperator: '>',
                };
              case 'rating':
                return {
                  key: f.value,
                  groupValuesLabel: '',
                  propertyLabel: f.label,
                  operators: [
                    {
                      operator: '=',
                      format: (value) => {
                        const rating = getByValue(fieldDef.ratingKey, value);

                        return rating?.label ?? '';
                      },
                      form: (props) => {
                        return (
                          <SelectFilter
                            {...props}
                            options={getOptions(fieldDef.ratingKey).map(
                              (o) => ({
                                value:
                                  (o.value as unknown as string) ?? undefined,
                                label: o.label,
                              })
                            )}
                          />
                        );
                      },
                    },
                  ],
                };
              case 'options':
                return {
                  key: f.value,
                  groupValuesLabel: '',
                  propertyLabel: f.label,
                  operators: [
                    {
                      operator: '=',
                      format: (value) => {
                        return (
                          fieldDef.getOptions().find((o) => o.value === value)
                            ?.label ?? ''
                        );
                      },
                      form: (props) => {
                        return (
                          <SelectFilter
                            {...props}
                            options={fieldDef.getOptions()}
                          />
                        );
                      },
                    },
                  ],
                };
              case 'text':

              // eslint-disable-next-line no-fallthrough
              default:
                return {
                  key: f.value,
                  groupValuesLabel: '',
                  propertyLabel: f.label,
                  operators: [
                    {
                      operator: '=',
                    },
                  ],
                };
            }
          }) ?? []
        }
        onChange={function (event) {
          setValue('filters', event.detail as Filters);
        }}
      />
    </FormField>
  );
};

export default ReportPropertyFilterPanel;
