import type { PropertyFilterOperation } from '@cloudscape-design/collection-hooks';
import { useTranslation } from 'react-i18next';

import type { Filter } from '@/components/CustomisableRibbon/Filter';

export const useGetDefaultRibbonFilters = (): Filter[] => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'tiers',
  });

  const defaultRibbonFilters = [
    {
      id: crypto.randomUUID(),
      title: t('1'),
      itemFilterQuery: {
        tokens: [
          {
            operator: '=',
            propertyKey: 'TierLabelled',
            value: t('1'),
          },
        ],
        operation: 'and' as PropertyFilterOperation,
      },
      itemOrder: 0,
    },
    {
      id: crypto.randomUUID(),
      title: t('2'),
      itemFilterQuery: {
        tokens: [
          {
            operator: '=',
            propertyKey: 'TierLabelled',
            value: t('2'),
          },
        ],
        operation: 'and' as PropertyFilterOperation,
      },
      itemOrder: 0,
    },
    {
      id: crypto.randomUUID(),
      title: t('3'),
      itemFilterQuery: {
        tokens: [
          {
            operator: '=',
            propertyKey: 'TierLabelled',
            value: t('3'),
          },
        ],
        operation: 'and' as PropertyFilterOperation,
      },
      itemOrder: 0,
    },
    {
      id: crypto.randomUUID(),
      title: 'All risks',
      itemFilterQuery: {
        tokens: [],
        operation: 'and' as PropertyFilterOperation,
      },
      itemOrder: 0,
    },
  ];

  return defaultRibbonFilters;
};
