import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import type { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import type {
  StatefulTableOptions,
  UseGetTablePropsOptions,
} from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetStatelessTableProps } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { addEnterpriseRiskUrl, enterpriseRiskDetailsUrl } from '@/utils/urls';

import { Permission } from '../../rbac/Permission';
import type {
  EnterpriseRiskFields,
  EnterpriseRiskRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

export const useGetFieldConfig =
  (): TableFields<EnterpriseRiskRegisterFields> => {
    const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
    const { t: st } = useTranslation(['common'], {
      keyPrefix: 'risks.columns',
    });

    return {
      Id: {
        header: t('guid'),
      },
      SequentialIdLabelled: {
        header: t('id'),
        sortingField: 'SequentialId',
      },
      Title: {
        header: t('title'),
        cell: (item) => (
          <Link variant="secondary" href={enterpriseRiskDetailsUrl(item.Id)}>
            {item.Title}
          </Link>
        ),
      },
      ParentTitle: {
        header: st('parent_risk'),
        cell: (item) => item.ParentTitle,
      },
      TierLabelled: {
        header: st('risk_tier'),
      },
      TreatmentLabelled: {
        header: st('risk_treatment'),
      },
      Description: {
        header: st('risk_description'),
        cell: (item) => item.Description,
        maxWidth: MAX_COL_WIDTH,
      },
      CreatedAtTimestamp: dateColumn(
        t('created_on'),
        'CreatedAtTimestamp',
        undefined,
        false,
        true
      ),
      ModifiedAtTimestamp: dateColumn(
        t('updated_on'),
        'ModifiedAtTimestamp',
        undefined,
        false,
        true
      ),
      CreatedByUser: {
        header: t('created_by_id'),
      },
      ModifiedByUser: {
        header: t('updated_by_id'),
      },
    };
  };

export const useGetEnterpriseRiskTableProps = (
  records: EnterpriseRiskFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<EnterpriseRiskRegisterFields> => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'enterpriseRisks',
  });
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(() => {
    return {
      tableId: 'enterpriseRiskRegister',
      data,
      fields,
      customAttributeSchema,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:enterprise_risk">
          <Button href={addEnterpriseRiskUrl()}>{st('createNewButton')}</Button>
        </Permission>
      ),
      enableFiltering: true,
      initialColumns: ['Title', 'ParentTitle', 'TierLabelled'],
      preferencesStorageKey: 'EnterpriseRisksTable-Preferences',
    };
  }, [st, data, fields, customAttributeSchema]);
};

export const useGetCollectionTableProps = (
  records: EnterpriseRiskFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<EnterpriseRiskRegisterFields> => {
  const props = useGetEnterpriseRiskTableProps(records, customAttributeSchema);

  return useGetTableProps(props);
};

export const useGetEnterpriseRiskSmartWidgetTableProps = (
  records: EnterpriseRiskFields[] | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  statefulTableOptions: StatefulTableOptions<EnterpriseRiskRegisterFields>
): TablePropsWithActions<EnterpriseRiskRegisterFields> => {
  const props = useGetEnterpriseRiskTableProps(records, customAttributeSchema);

  return useGetStatelessTableProps<EnterpriseRiskRegisterFields>({
    ...props,
    ...statefulTableOptions,
    enableFiltering: false,
  });
};
