import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import Table from '@risksmart-app/components/Table';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import CustomisableRibbon from '@/components/CustomisableRibbon/CustomisableRibbon';
import {
  Parent_Type_Enum,
  useGetEnterpriseRisksQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { addEnterpriseRiskUrl } from '@/utils/urls';

import { useGetCollectionTableProps } from './config';
import { defaultRibbonFilters } from './defaultRibbonFilters';

const EnterpriseRiskPage: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'enterpriseRisks' });

  const { data, loading } = useGetEnterpriseRisksQuery();

  const tableProps = useGetCollectionTableProps(
    data?.enterprise_risk,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      title={t('registerTitle')}
      helpTranslationKey="enterpriseRisks.registerHelp"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Permission permission="insert:enterprise_risk">
            <Button variant="primary" href={addEnterpriseRiskUrl()}>
              {t('createNewButton')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <CustomisableRibbon
        items={tableProps.allItems}
        propertyFilterQuery={tableProps.propertyFilterQuery}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        filteringOptions={tableProps.propertyFilterProps.filteringOptions}
        parentType={Parent_Type_Enum.EnterpriseRisk}
        defaultFilters={defaultRibbonFilters}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default EnterpriseRiskPage;
