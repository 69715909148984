import i18n from '@risksmart-app/components/providers/i18n';
import type { risks as sharedDataset } from '@risksmart-app/shared/reporting/datasets/risks';

import type { FrontEndDataSource } from './types';

export const risks: FrontEndDataSource<typeof sharedDataset> = {
  label: 'Risks',
  fields: {
    title: { displayType: 'text', label: 'Title' },
    id: { displayType: 'text', label: 'Id' },
    parentRiskId: { displayType: 'text', label: 'Parent Id' },
    tier: {
      label: 'Tier',
      displayType: 'options',
      getOptions: () => {
        const tiers = i18n.t('tiers');

        return Object.keys(tiers).map((tier) => ({
          value: tier,
          label: tiers[tier as keyof typeof tiers],
        }));
      },
    },
    createdAtTimestamp: { displayType: 'date', label: 'Created at' },
  },
};
