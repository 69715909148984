import type { FC } from 'react';
import type { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';

import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';

import IssueForm from './update/forms/IssueForm';

type Props = {
  onDismiss: () => void;
  onSaving: (action: IssueFormDataFields) => Promise<void>;
  issueType: ParentIssueTypes;
};

const IssueModal: FC<Props> = ({ onDismiss, onSaving, issueType }) => (
  <IssueForm
    onSave={onSaving}
    onDismiss={onDismiss}
    issueType={issueType}
    renderTemplate={(renderProps) => (
      <ModalWrapper {...renderProps} visible={true} />
    )}
  />
);

export default IssueModal;
