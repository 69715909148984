import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/useHasPermission';

import { contributorUpdateFields, ownerUpdateFields } from '@/components/Form';
import { defaultValues } from '@/components/Form/CustomAttributes/EditFields/NewFieldSchema';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  namedOperations,
  useGetQuestionnaireTemplateByIdQuery,
  useUpdateQuestionnaireTemplateMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import { QuestionnaireTemplateForm } from '../../../forms/QuestionnaireTemplateForm';
import type { QuestionnaireTemplateFormData } from '../../../forms/questionnaireTemplateSchema';

const Tab: FC = () => {
  useI18NSummaryHelpContent('questionnaire_templates.help');

  const navigate = useNavigate();

  const questionnaireTemplateId = useGetGuidParam('questionnaireTemplateId');
  const { data } = useGetQuestionnaireTemplateByIdQuery({
    variables: { Id: questionnaireTemplateId },
  });

  const questionnaireTemplate = data?.questionnaire_template;

  const canEdit = useHasPermission(
    'update:questionnaire_template',
    questionnaireTemplate
  );

  const [mutate] = useUpdateQuestionnaireTemplateMutation({
    update: (cache) => {
      evictField(cache, 'questionnaire_template');
      evictField(cache, 'questionnaire_template_by_pk');
    },
    refetchQueries: [
      namedOperations.Query.getQuestionnaireTemplateById,
      namedOperations.Query.getQuestionnaireTemplates,
    ],
  });

  if (!questionnaireTemplate) {
    return null;
  }

  const onSave = async (formData: QuestionnaireTemplateFormData) => {
    if (!data?.questionnaire_template?.Id) {
      throw new Error('Questionnaire template ID is not available');
    }

    await mutate({
      variables: {
        Id: data.questionnaire_template.Id,
        object: {
          Title: formData.Title,
          Description: formData.Description,
          CustomAttributeData: formData.CustomAttributeData,
        },
        ...contributorUpdateFields(formData, questionnaireTemplateId),
        ...ownerUpdateFields(formData, questionnaireTemplateId),
        tags: formData.tags.map((t) => ({
          ...t,
          ParentId: questionnaireTemplateId,
        })),
        departments: formData.departments.map((d) => ({
          ...d,
          ParentId: questionnaireTemplateId,
        })),
        TagTypeIds: formData.TagTypeIds,
        DepartmentTypeIds: formData.DepartmentTypeIds,
      },
    });
  };

  const onDismiss = () => {
    navigate(-1);
  };

  return (
    <QuestionnaireTemplateForm
      values={{
        ...defaultValues,
        Title: questionnaireTemplate.Title,
        Description: questionnaireTemplate.Description,
        Owners: getOwners(questionnaireTemplate),
        Contributors: getContributors(questionnaireTemplate),
        ancestorContributors: questionnaireTemplate.ancestorContributors,
        tags: questionnaireTemplate.tags,
        departments: questionnaireTemplate.departments,
        CustomAttributeData: questionnaireTemplate.CustomAttributeData,
        TagTypeIds: [],
        DepartmentTypeIds: [],
      }}
      readOnly={!canEdit}
      onSave={onSave}
      onDismiss={onDismiss}
    />
  );
};

export default Tab;
