import i18n from '@risksmart-app/components/providers/i18n';
import _ from 'lodash';
import { useMemo } from 'react';
import type { IssueRegisterFields } from 'src/pages/issues/types';

import {
  Issue_Assessment_Status_Enum,
  Parent_Type_Enum,
} from '@/generated/graphql';
import { GetIssuesDocument } from '@/generated/graphql.typed';
import type { ParentIssueTypes } from '@/utils/issueVariantUtils';
import { IssueTypeMapping } from '@/utils/issueVariantUtils';

import { useGetIssueSmartWidgetTableProps } from '../../../issues/config';
import { UNRATED } from '../../Gigawidget/types';
import { departmentGetter } from '../../Gigawidget/util/categoryGetters';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateRangeClickthroughFilter,
  dateRangeClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
  tagAndDepartmentCategoryGetters,
} from '../dataSourceHelpers';

export default (issueType: ParentIssueTypes) =>
  createDataSource({
    parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
    useRelativeCustomAttributeDates: true,
    hasAccess: (_, isFeatureVisibleToOrg) =>
      issueType === Parent_Type_Enum.Issue ||
      !!(
        IssueTypeMapping[issueType].featureFlag &&
        isFeatureVisibleToOrg(IssueTypeMapping[issueType].featureFlag!)
      ),
    documentNode: GetIssuesDocument,
    defaultVariables: () => ({
      where: {
        Type: {
          _in: [issueType],
        },
      },
      parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
    }),
    useTablePropsHook: (data, options) => {
      const customAttributeSchema = useMemo(
        () =>
          data?.form_configuration
            .filter((config) => !!config.customAttributeSchema)
            .map((config) => config.customAttributeSchema!) ?? [],
        [data?.form_configuration]
      );

      return useGetIssueSmartWidgetTableProps(
        issueType,
        data?.issue,
        customAttributeSchema,
        options
      );
    },
    entityNamePlural: IssueTypeMapping[issueType].entityLabelOther,
    entityNameSingular: IssueTypeMapping[issueType].entityLabel,
    fields: `${IssueTypeMapping[issueType].taxonomy}.fields`,
    dashboardFilterConfig: {
      tagsFilter: (tags) => ({
        where: {
          tags: tagsFilter(tags),
          Type: {
            _in: [issueType],
          },
        },
        parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
      }),
      departmentsFilter: (departments) => ({
        where: {
          departments: departmentsFilter(departments),
          Type: {
            _in: [issueType],
          },
        },
        parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
      }),
      dateFilter: (dateRange, precision) => ({
        where: {
          CreatedAtTimestamp: dateRangeFilter(dateRange, precision),
          Type: {
            _in: [issueType],
          },
        },
        parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
      }),
      dateClickthroughFilter:
        dashboardDateRangeClickthroughFilter('CreatedAtTimestamp'),
    },
    clickThroughUrl: (filter, sorter) =>
      IssueTypeMapping[issueType].registerUrl({
        filtering: filter,
        sorting: sorter,
      }),
    categoryGetters: [
      ...tagAndDepartmentCategoryGetters<IssueRegisterFields>(),
      {
        id: 'assessmentDepartments',
        name: () =>
          i18n.t(
            `${IssueTypeMapping[issueType].taxonomy}.columns.assessment_departments`
          ),
        categoryGetter: (item) =>
          item.assessment
            ? departmentGetter({ includeNoDepartments: true })(item.assessment)
            : null,
        clickthroughFilter: (category) => [
          {
            propertyKey: 'AssessmentDepartments',
            operator: category.key === UNRATED ? '<' : '=',
            value: category.key === UNRATED ? 1 : category.key,
          },
        ],
      },
      {
        id: 'status',
        name: () =>
          i18n.t(`${IssueTypeMapping[issueType].taxonomy}.columns.status`),
        categoryGetter: (item) => ({
          key: item.StatusLabelled
            ? item.StatusLabelled.toLowerCase()
            : Issue_Assessment_Status_Enum.Pending,
          label: item.StatusLabelled ?? UNRATED,
        }),
        clickthroughFilter:
          defaultClickthroughFilterWithUnrated('StatusLabelled'),
        ratingColourKey: 'issue_assessment_status',
      },
      {
        id: 'owner',
        name: () =>
          i18n.t(`${IssueTypeMapping[issueType].taxonomy}.columns.owner`),
        categoryGetter: (item) =>
          item.allOwners.map((owner) => ({
            key: owner.id,
            label: owner.label,
          })),
        clickthroughFilter: (category) => [
          {
            propertyKey: 'allOwners',
            operator: '=',
            value: category.key,
          },
        ],
      },
      {
        id: 'impactsCustomers',
        name: () =>
          i18n.t(
            `${IssueTypeMapping[issueType].taxonomy}.columns.impacts_customer`
          ) + ' (Yes/No)',
        categoryGetter: (item) => ({
          key: String(item.ImpactsCustomer),
          label: _.isNil(item.ImpactsCustomer)
            ? 'Unspecified'
            : item.ImpactsCustomer
              ? 'Yes'
              : 'No',
        }),
        clickthroughFilter: defaultClickthroughFilterWithUnrated(
          'ImpactsCustomer',
          {
            categoryValue: 'key',
          }
        ),
      },
      {
        id: 'internalExternal',
        name: () =>
          i18n.t(
            `${IssueTypeMapping[issueType].taxonomy}.columns.is_external_issue`
          ) + ' (Yes/No)',
        categoryGetter: (item) => ({
          key: String(item.IsExternalIssue),
          label: _.isNil(item.IsExternalIssue)
            ? 'Unspecified'
            : item.IsExternalIssue
              ? 'External'
              : 'Internal',
        }),
        clickthroughFilter: defaultClickthroughFilterWithUnrated(
          'IsExternalIssue',
          {
            categoryValue: 'key',
          }
        ),
      },
      {
        id: 'severity',
        name: () =>
          i18n.t(`${IssueTypeMapping[issueType].taxonomy}.columns.severity`),
        categoryGetter: (item) => ({
          key: item.Severity ?? UNRATED,
          label: item.SeverityLabelled ?? UNRATED,
        }),
        ratingColourKey: 'severity',
        clickthroughFilter:
          defaultClickthroughFilterWithUnrated('SeverityLabelled'),
      },
      {
        id: 'type',
        name: () =>
          i18n.t(`${IssueTypeMapping[issueType].taxonomy}.columns.type`),
        categoryGetter: (item) => ({
          key: item.IssueType ?? UNRATED,
          label: item.IssueType ? item.IssueTypeLabelled : 'No Type',
        }),
        clickthroughFilter:
          defaultClickthroughFilterWithUnrated('IssueTypeLabelled'),
      },
      {
        id: 'dateIdentified',
        name: () =>
          i18n.t(
            `${IssueTypeMapping[issueType].taxonomy}.columns.date_identified`
          ),
        categoryGetter: (data) => new Date(data.DateIdentified),
        date: true,
        dashboardDateFilterOverride: (dateRange, precision) => ({
          where: {
            DateIdentified: dateRangeFilter(dateRange, precision),
            Type: {
              _in: [issueType],
            },
          },
          parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
        }),
        clickthroughFilter: dateRangeClickthroughFilter('DateIdentified'),
      },
      {
        id: 'dateOccurred',
        name: () =>
          i18n.t(
            `${IssueTypeMapping[issueType].taxonomy}.columns.date_occurred`
          ),
        categoryGetter: (data) => new Date(data.DateOccurred),
        date: true,
        dashboardDateFilterOverride: (dateRange, precision) => ({
          where: {
            DateOccurred: dateRangeFilter(dateRange, precision),
            Type: {
              _in: [issueType],
            },
          },
          parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
        }),
        clickthroughFilter: dateRangeClickthroughFilter('DateOccurred'),
      },
      {
        id: 'createdDate',
        name: () =>
          i18n.t(`${IssueTypeMapping[issueType].taxonomy}.columns.raised`) +
          ' Date',
        categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
        date: true,
        dashboardDateFilterOverride: (dateRange, precision) => ({
          where: {
            CreatedAtTimestamp: dateRangeFilter(dateRange, precision),
            Type: {
              _in: [issueType],
            },
          },
          parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
        }),
        clickthroughFilter: dateRangeClickthroughFilter('CreatedAtTimestamp'),
      },

      {
        id: 'actualCloseDate',
        name: () =>
          i18n.t(
            `${IssueTypeMapping[issueType].taxonomy}.columns.actual_close_date`
          ),
        categoryGetter: (data) =>
          data.ActualCloseDate ? new Date(data.ActualCloseDate) : null,
        date: true,
        dashboardDateFilterOverride: (dateRange, precision) => ({
          where: {
            ActualCloseDate: dateRangeFilter(dateRange, precision),
            Type: {
              _in: [issueType],
            },
          },
          parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
        }),
        clickthroughFilter: dateRangeClickthroughFilter('ActualCloseDate'),
      },
      {
        id: 'targetCloseDate',
        name: () =>
          `${i18n.t(`${IssueTypeMapping[issueType].taxonomy}.columns.target_close_date`)}`,
        categoryGetter: (data) =>
          data.TargetCloseDate ? new Date(data.TargetCloseDate) : null,
        date: true,
        dashboardDateFilterOverride: (dateRange, precision) => ({
          where: {
            TargetCloseDate: dateRangeFilter(dateRange, precision),
            Type: {
              _in: [issueType],
            },
          },
          parentTypes: [issueType, IssueTypeMapping[issueType].assessmentType],
        }),
        clickthroughFilter: dateRangeClickthroughFilter('TargetCloseDate'),
      },
    ],
  });
