import type { TabsProps } from '@cloudscape-design/components/tabs';
import { useTranslation } from 'react-i18next';

import type { GetThirdPartyByIdQuery } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { useIssueVariantTabs } from '@/utils/issueVariantUtils';
import { filterEmptyTabs } from '@/utils/tabUtils';

import { useHasPermission } from '../../../rbac/useHasPermission';
import ActionsTab from '../../actions/ActionsTab';
import ControlsTab from '../../controls/tab/Tab';
import LinkedItemsTab from '../../linked-items/LinkedItemsTab';
import ThirdPartyCreateTab from '../create/tabs/ThirdPartyCreateTab';
import DetailsTab from './tabs/details/DetailsTab';
import QuestionnairesTab from './tabs/questionnaires/QuestionnairesTab';

export const useTabs = (thirdParty?: GetThirdPartyByIdQuery['third_party']) => {
  const detailsOnly = !thirdParty;
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party.tabs',
  });

  const canViewControls = useHasPermission('read:control', thirdParty);
  const canViewIssues = useHasPermission('read:issue', thirdParty);
  const canViewActions = useHasPermission('read:action', thirdParty);
  const canViewLinkedItems = useHasPermission('read:linked_item', thirdParty);
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewQuestionnaires = useIsFeatureVisibleToOrg(
    'questionnaire_builder'
  );
  const issueTabs = useIssueVariantTabs(
    `/third-party/${thirdParty?.Id}`,
    detailsOnly,
    thirdParty
  );

  const tabs: TabsProps.Tab[] = [
    {
      label: st('details'),
      id: 'details',
      content: detailsOnly ? <ThirdPartyCreateTab /> : <DetailsTab />,
      href: detailsOnly ? undefined : `/third-party/${thirdParty.Id}`,
    },
  ];

  if (canViewQuestionnaires) {
    tabs.push({
      label: st('questionnaires'),
      id: 'questionnaires',
      content: <QuestionnairesTab />,
      href: `/third-party/${thirdParty?.Id}/questionnaire-responses`,
      disabled: detailsOnly,
    });
  }

  if (canViewControls) {
    tabs.push({
      label: t('controls.tab_title'),
      id: 'controls',
      content: thirdParty && <ControlsTab parent={thirdParty} />,
      href: `/third-party/${thirdParty?.Id}/controls`,
      disabled: detailsOnly,
    });
  }

  if (canViewIssues) {
    tabs.push(...issueTabs);
  }

  if (canViewActions) {
    tabs.push({
      label: t('actions.tab_title'),
      id: 'actions',
      content: thirdParty && <ActionsTab parent={thirdParty} />,
      href: `/third-party/${thirdParty?.Id}/actions`,
      disabled: detailsOnly,
    });
  }

  if (canViewLinkedItems && linkedItemsEnabled) {
    tabs.push({
      label: t('linkedItems.tab_title'),
      id: 'linkedItems',
      content: thirdParty && <LinkedItemsTab parent={thirdParty} />,
      href: `/third-party/${thirdParty?.Id}/linked-items`,
      disabled: detailsOnly,
    });
  }

  return filterEmptyTabs(tabs);
};
