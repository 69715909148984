import type { JsonSchema7 } from '@jsonforms/core';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand/index';

import { handleError } from '../../utils/errorUtils';
import type {
  CustomUISchema,
  CustomUISchemaElement,
  SectionData,
} from '../types';
import { excludeRequiredPropertyFromRequiredList } from './sharedUtils';
import { useFormBuilderStore } from './useFormBuilderStore';

type FormBuilderSectionState = {
  addNewSection: (sectionData: SectionData) => void;
  updateSection: (
    sectionData: SectionData,
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => void;
  deleteSection: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => void;
};

export const useFormBuilderSectionStore = create<FormBuilderSectionState>(
  () => ({
    addNewSection: (sectionData: SectionData) => {
      const uiSchema = useFormBuilderStore.getState().uiSchema;
      const setUISchema = useFormBuilderStore.getState().setUISchema;

      const uuid = `section_${uuidv4()}`;

      // Add new section to the UI Schema
      setUISchema({
        ...uiSchema,
        elements: [
          ...uiSchema.elements,
          {
            type: 'Group',
            label: sectionData.sectionTitle,
            id: uuid,
            elements: [],
          },
        ],
      });
    },

    updateSection: (
      sectionData: SectionData,
      currentElementUISchema: CustomUISchema | CustomUISchemaElement
    ) => {
      const uiSchema = useFormBuilderStore.getState().uiSchema;
      const setUISchema = useFormBuilderStore.getState().setUISchema;

      // Iterate through all the sections (found in the elements array of the UI Schema)
      // and update the label of the section that matches the section being edited
      const modifiedElementsCopy = uiSchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === currentElementUISchema?.id) {
            return {
              ...element,
              label: sectionData.sectionTitle,
            };
          }

          return element;
        }
      );

      // Update the UI Schema with the list of modified sections
      setUISchema({
        ...uiSchema,
        elements: modifiedElementsCopy,
      });
    },

    deleteSection: (
      currentElementUISchema: CustomUISchema | CustomUISchemaElement
    ) => {
      const schema = useFormBuilderStore.getState().schema;
      const setSchema = useFormBuilderStore.getState().setSchema;
      const uiSchema = useFormBuilderStore.getState().uiSchema;
      const setUISchema = useFormBuilderStore.getState().setUISchema;

      if (!currentElementUISchema?.id) {
        handleError(
          new Error('useFormBuilderSectionStore: No id found in uiSchema')
        );

        return;
      }

      if (!schema?.properties) {
        handleError(
          new Error('useFormBuilderSectionStore: No properties found in schema')
        );

        return;
      }

      // Iterate through all the sections (found in the elements array of the UI Schema)
      // and delete the section that matches the section being deleted
      const modifiedUISchemaElementsCopy = uiSchema.elements.filter(
        (element: CustomUISchemaElement) =>
          element.id !== currentElementUISchema.id
      );

      // Update the UI Schema with the list of sections (excluding the deleted section)
      setUISchema({
        ...uiSchema,
        elements: modifiedUISchemaElementsCopy,
      });

      // ...THEN, update the schema to remove any properties (form fields) that belong to the deleted section
      const modifiedSchemaPropertiesCopy = Object.keys(
        schema.properties || {}
      ).reduce(
        (acc, key) => {
          if (schema?.properties === undefined) {
            return acc;
          }

          const entity = schema.properties![key] as {
            [property: string]: JsonSchema7 & { parentId?: string };
          };

          if (entity?.parentId !== currentElementUISchema.id) {
            acc![key] = schema.properties![key];
          }

          return acc;
        },
        {} as JsonSchema7['properties']
      );

      // Update the schema with the list of sections (excluding the questions from the deleted section)
      // AND removing them from the `required` list
      setSchema({
        ...schema,
        properties: { ...modifiedSchemaPropertiesCopy },
        required: excludeRequiredPropertyFromRequiredList({
          propertyIds: Object.keys(modifiedSchemaPropertiesCopy || {}),
          uiSchemaId: currentElementUISchema.id,
        }),
      });
    },
  })
);
