import theme from '@risksmart-app/theme';
import chroma from 'chroma-js';

const THRESHOLD = 2.5;
const WHITE = '#ffffff';
const LIGHT_TEXT = '#ffffff';
const DARK_TEXT = '#2D2D53';
const DARK_BG = '#fbfbfb';

export const colours = {
  'dark-green': { backgroundColor: '#6DAC3F', color: LIGHT_TEXT },
  'light-green': { backgroundColor: '#8CC862', color: LIGHT_TEXT },
  orange: { backgroundColor: '#F2A041', color: LIGHT_TEXT },
  'light-red': { backgroundColor: '#E37373', color: LIGHT_TEXT },
  'dark-red': { backgroundColor: '#CE1B1B', color: LIGHT_TEXT },
  'charts-yellow-300': { backgroundColor: '#b2911c', color: DARK_TEXT },
  'charts-blue-1-400': { backgroundColor: '#3184c2', color: DARK_TEXT },
  'charts-blue-1-300': { backgroundColor: '#529ccb', color: DARK_TEXT },
  'charts-teal-300': { backgroundColor: '#2ea597', color: DARK_TEXT },
  'charts-grey-450': { backgroundColor: '#5f6b7a', color: DARK_TEXT },
  'light-grey': { backgroundColor: '#E8E8EC', color: '#73738C' },
  'light-gray': { backgroundColor: '#E8E8EC', color: '#73738C' },
  black: { backgroundColor: '#000000', color: DARK_TEXT },
  'darker-green': { backgroundColor: '#048e6b', color: LIGHT_TEXT },
  'light-yellow': { backgroundColor: '#ffe896', color: DARK_TEXT },
  'lighter-orange': { backgroundColor: '#ffa26b', color: DARK_TEXT },
  'darker-orange': { backgroundColor: '#ec8243', color: DARK_TEXT },
  'strong-red': { backgroundColor: '#ff3e68', color: LIGHT_TEXT },
  'blue-100': { backgroundColor: '#f2f8fd', color: DARK_TEXT },
  'blue-200': { backgroundColor: '#d3e7f9', color: DARK_TEXT },
  'blue-300': { backgroundColor: '#b5d6f4', color: DARK_TEXT },
  'blue-400': { backgroundColor: '#89bdee', color: DARK_TEXT },
  'blue-500': { backgroundColor: '#539fe5', color: LIGHT_TEXT },
  'blue-600': { backgroundColor: '#0972d3', color: LIGHT_TEXT },
  'blue-700': { backgroundColor: '#065299', color: LIGHT_TEXT },
} as const;

// Colours taken from cloudscape recommended palette for generic categorical data
// https://cloudscape.design/foundation/visual-foundation/data-vis-colors/#generic-categorical-palette

const genericChartColours = [
  theme.colors.teal,
  '#c33d69', // = charts-pink-500
  '#688ae8', // = charts-blue-2-300
  '#8456ce', // = charts-purple-500
  '#e07941', // = charts-orange-300
  '#3759ce', // = charts-blue-2-600
  '#962249', // = charts-pink-800
  '#096f64', // = charts-teal-600
  '#6237a7', // = charts-purple-800
  '#a84401', // = charts-orange-600
  '#273ea5', // = charts-blue-2-900
  '#780d35', // = charts-pink-1100
  '#03524a', // = charts-teal-900
  '#4a238b', // = charts-purple-1100,
  '#7e3103', // = charts-orange-900,
  '#1b2b88', // = charts-blue-2-1200
];

export const genericCategoricalPalette = (index: number) => {
  return genericChartColours[index % genericChartColours.length];
};

const colorKeys = Object.keys(colours) as (keyof typeof colours)[];

const isColorKey = (key: string): key is keyof typeof colours =>
  colorKeys.includes(key as (typeof colorKeys)[number]);

export const getAccessibleTextColor = (colorName: string) =>
  chroma.contrast(colorName, WHITE) > THRESHOLD ? LIGHT_TEXT : DARK_TEXT;

const DEFAULT = {
  backgroundColor: DARK_BG,
  color: LIGHT_TEXT,
};
export const getColorStyles = (
  colorName: (typeof colorKeys)[number] | string | undefined
) => {
  if (!colorName) {
    return DEFAULT;
  }

  /* Compare input to lookup table */
  if (isColorKey(colorName)) {
    return {
      backgroundColor: colours[colorName].backgroundColor,
      color: getAccessibleTextColor(colours[colorName].backgroundColor),
    };
  }

  if (chroma.valid(colorName)) {
    const color = getAccessibleTextColor(colorName);

    const backgroundColor = String(chroma(colorName));

    return { backgroundColor, color };
  } else {
    console.error(`Invalid colour ${colorName}`);
  }

  return DEFAULT;
};

export type Colour = keyof typeof colours;
