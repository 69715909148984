import { DataSourceType } from '../../reporting/schema';
import { SharedDataset } from './types';

export const risks = {
  parentTypes: new Set(['risks']) as Set<DataSourceType>,
  fields: {
    title: true,
    id: true,
    parentRiskId: true,
    tier: true,
    createdAtTimestamp: true,
  },
} satisfies SharedDataset;
