import { JsonForms } from '@jsonforms/react';
import { useShallow } from 'zustand/react/shallow';

import { FormBuilderAddSection } from './FormBuilderAddSection';
import { FormQuestionModal } from './FormQuestionModal';
import rendererRegistry from './renderers/registry';
import { useFormBuilderStore } from './store/useFormBuilderStore';

export const FormBuilder = () => {
  const {
    formData,
    setFormData,
    schema,
    uiSchema,
    setUISchema,
    isFormCustomisable,
  } = useFormBuilderStore(
    useShallow((state) => ({
      formData: state.formData,
      setFormData: state.setFormData,
      schema: state.schema,
      uiSchema: state.uiSchema,
      setUISchema: state.setUISchema,
      isFormCustomisable: state.isFormCustomisable,
    }))
  );

  return (
    <>
      <div key={'form-builder'} className={'flex flex-col gap-y-4 mb-8'}>
        {/*
          Avoid spreading data into Json Forms as it is a
          known anti-pattern that causes infinite render loops
          https://jsonforms.io/faq/#how-can-i-minimize-re-rendering
        */}
        <JsonForms
          readonly={true}
          data={formData}
          schema={schema}
          uischema={uiSchema}
          renderers={rendererRegistry}
          onChange={({ data }) => setFormData(data)}
          validationMode={'NoValidation'}
        />

        {isFormCustomisable ? (
          <>
            <FormBuilderAddSection
              schema={schema}
              uiSchema={uiSchema}
              setUISchema={setUISchema}
            />

            <FormQuestionModal />
          </>
        ) : null}
      </div>
    </>
  );
};
