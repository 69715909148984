export const getFieldLabel = (
  label: string | undefined,
  required?: boolean
) => {
  if (!label) {
    return '';
  }

  if (required) {
    return `${label}`;
  }

  return `${label} (optional)`;
};
