import { z } from 'zod';

export const dataSourceTypes = [
  'risks',
  'actions',
  'issues',
  'controls',
] as const;

export const dataSourceTypeSchema = z.enum(dataSourceTypes);

export type DataSourceType = z.infer<typeof dataSourceTypeSchema>;

export const selectedDatasourceSchema = z.object({
  type: dataSourceTypeSchema,
  /**
   * Parent datasource to join with
   */
  parentIndex: z.number().int().optional(),
});

export type SelectedDatasource = z.infer<typeof selectedDatasourceSchema>;
