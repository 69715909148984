import type { DataSourceType } from '@risksmart-app/shared/reporting/schema';

import { frontendDataSources } from './datasets';
import type { FieldDefinition } from './datasets/types';
import type { TreeDataSource } from './definitionSchema';

type RelatedDataSource = { type: DataSourceType; parentIndex?: number };

/**
 * Converts a tree of data sources into a list, with joins based on parent index
 * @param root
 * @returns
 */
export const getFlattenedDataSources = (
  root: TreeDataSource
): RelatedDataSource[] => {
  const dataSources: RelatedDataSource[] = [];

  const flattenDataSourceTree = (
    datasource: TreeDataSource,
    parentIndex: number | undefined
  ) => {
    if (!datasource.type) {
      return;
    }
    dataSources.push({
      type: datasource.type,
      parentIndex,
    });
    const childParentIndex = parentIndex == undefined ? 0 : ++parentIndex;
    datasource.children.forEach((childDataSource) => {
      flattenDataSourceTree(childDataSource, childParentIndex);
    });
  };
  flattenDataSourceTree(root, undefined);

  return dataSources;
};

/**
 * Possible field that can be selected for given data sources
 */
export type AllowedField = {
  value: string;
  label: string;
  dataSourceIndex: number;
  fieldId: string;
  fieldDef: FieldDefinition;
};

/**
 * Get list of fields that can be displayed based on the selected dta sources
 * @param root
 * @returns
 */
export const getAllowedFields = (
  root: TreeDataSource | undefined
): AllowedField[] => {
  const fields: AllowedField[] = [];
  if (!root?.type) {
    return fields;
  }
  const dataSources = getFlattenedDataSources(root);

  dataSources.forEach((dataSource, index) => {
    const ds = frontendDataSources[dataSource.type];

    const getLabel = (fieldId: string) => {
      const field = ds.fields[fieldId];
      let label = field.label;

      let parentDataSource: RelatedDataSource | null = dataSource;

      while (parentDataSource) {
        const parentDs = frontendDataSources[parentDataSource.type];
        label = `${parentDs.label} / ${label}`;
        if (parentDataSource.parentIndex !== undefined) {
          parentDataSource = dataSources[parentDataSource.parentIndex];
        } else {
          parentDataSource = null;
        }
      }

      return label;
    };

    for (const fieldId in ds.fields) {
      fields.push({
        value: `${index}|${fieldId}`,
        fieldId,
        label: getLabel(fieldId),
        dataSourceIndex: index,
        fieldDef: ds.fields[fieldId],
      });
    }
  });

  return fields;
};
