import { useKnockFeed } from '@knocklabs/react';
import Count from '@risksmart-app/components/Navigation/Count';
import type { FC } from 'react';

import {
  useGetAcceptanceCountQuery,
  useGetAppetiteCountQuery,
  useGetAssessmentCountQuery,
  useGetAssessmentResultCountQuery,
  useGetCauseCountQuery,
  useGetComplianceMonitoringAssessmentCountQuery,
  useGetComplianceMonitoringAssessmentResultCountQuery,
  useGetConsequenceCountQuery,
  useGetControlCountQuery,
  useGetControlGroupCountQuery,
  useGetImpactCountQuery,
  useGetImpactRatingCountQuery,
  useGetInternalAuditReportCountQuery,
  useGetInternalAuditReportResultCountQuery,
  useGetIssueCountQuery,
  useGetRiskCountQuery,
  useGetTestResultCountQuery,
} from '@/generated/graphql';

export const CountOptions = [
  'issue',
  'issueRiskEvent',
  'issueBreachLog',
  'issueConsumerDuty',
  'issueCustomerTrust',
  'issueGDPRBreachLog',
  'issuePCIBreachLog',
  'issueSARLog',
  'risk',
  'acceptance',
  'appetite',
  'notification',
  'impact',
  'impactRating',
  'assessment',
  'internalAudit',
  'internalAuditReport',
  'assessmentResult',
  'internalAuditReportResult',
  'complianceMonitoringAssessment',
  'complianceMonitoringAssessmentResult',
  'consequence',
  'cause',
  'request',
  'control',
  'controlGroup',
  'testResult',
] as const;
export type CountOptions = (typeof CountOptions)[number];

const ConnectedCount: FC<{
  isActive?: boolean;
  badge?: boolean;
  countName: CountOptions;
}> = ({ isActive, badge, countName }) => {
  const knock = useKnockFeed();

  const { data: issueData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue',
        },
      },
    },
    skip: countName !== 'issue',
  });
  const { data: issueRiskEventData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_risk_event',
        },
      },
    },
    skip: countName !== 'issueRiskEvent',
  });
  const { data: issueConsumerDutyData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_consumer_duty',
        },
      },
    },
    skip: countName !== 'issueConsumerDuty',
  });
  const { data: issueCustomerTrustData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_customer_trust',
        },
      },
    },
    skip: countName !== 'issueCustomerTrust',
  });
  const { data: issueGDPRBreachLogData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_gdpr_breach_log',
        },
      },
    },
    skip: countName !== 'issueGDPRBreachLog',
  });
  const { data: issuePCIBreachLogData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_pci_breach_log',
        },
      },
    },
    skip: countName !== 'issuePCIBreachLog',
  });
  const { data: issueSARLogData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_sar_log',
        },
      },
    },
    skip: countName !== 'issueSARLog',
  });
  const { data: issueBreachLogData } = useGetIssueCountQuery({
    variables: {
      where: {
        Type: {
          _eq: 'issue_breach_log',
        },
      },
    },
    skip: countName !== 'issueBreachLog',
  });
  const { data: consequenceData } = useGetConsequenceCountQuery({
    skip: countName !== 'consequence',
  });
  const { data: causeData } = useGetCauseCountQuery({
    skip: countName !== 'cause',
  });
  const { data: impactData } = useGetImpactCountQuery({
    skip: countName !== 'impact',
  });
  const { data: impactRatingData } = useGetImpactRatingCountQuery({
    skip: countName !== 'impactRating',
  });
  const { data: riskData } = useGetRiskCountQuery({
    skip: countName !== 'risk',
  });
  const { data: appetiteData } = useGetAppetiteCountQuery({
    skip: countName !== 'appetite',
  });
  const { data: acceptanceData } = useGetAcceptanceCountQuery({
    skip: countName !== 'acceptance',
  });
  const { data: assessmentData } = useGetAssessmentCountQuery({
    skip: countName !== 'assessment',
  });
  const { data: internalAuditReportData } = useGetInternalAuditReportCountQuery(
    {
      skip: countName !== 'internalAuditReport',
    }
  );
  const { data: complianceMonitoringAssessmentData } =
    useGetComplianceMonitoringAssessmentCountQuery({
      skip: countName !== 'complianceMonitoringAssessment',
    });
  const { data: assessmentResultData } = useGetAssessmentResultCountQuery({
    skip: countName !== 'assessmentResult',
  });

  const { data: internalAuditReportResultData } =
    useGetInternalAuditReportResultCountQuery({
      skip: countName !== 'internalAuditReportResult',
    });

  const { data: complianceMonitoringAssessmentResultData } =
    useGetComplianceMonitoringAssessmentResultCountQuery({
      skip: countName !== 'complianceMonitoringAssessmentResult',
    });

  const { data: controlData } = useGetControlCountQuery({
    skip: countName !== 'control',
  });
  const { data: controlGroupData } = useGetControlGroupCountQuery({
    skip: countName !== 'controlGroup',
  });
  const { data: testResultData } = useGetTestResultCountQuery({
    skip: countName !== 'testResult',
  });
  const notificationsCount = knock.useFeedStore(
    (state) => state.items.filter((i) => !i.read_at).length
  );

  let count: number | undefined = undefined;
  switch (countName) {
    case 'notification':
      count = notificationsCount;
      break;
    case 'issue':
      count = issueData?.issue_aggregate.aggregate?.count;
      break;
    case 'issueBreachLog':
      count = issueBreachLogData?.issue_aggregate.aggregate?.count;
      break;
    case 'issueConsumerDuty':
      count = issueConsumerDutyData?.issue_aggregate.aggregate?.count;
      break;
    case 'issueCustomerTrust':
      count = issueCustomerTrustData?.issue_aggregate.aggregate?.count;
      break;
    case 'issueGDPRBreachLog':
      count = issueGDPRBreachLogData?.issue_aggregate.aggregate?.count;
      break;
    case 'issuePCIBreachLog':
      count = issuePCIBreachLogData?.issue_aggregate.aggregate?.count;
      break;
    case 'issueSARLog':
      count = issueSARLogData?.issue_aggregate.aggregate?.count;
      break;
    case 'issueRiskEvent':
      count = issueRiskEventData?.issue_aggregate.aggregate?.count;
      break;
    case 'cause':
      count = causeData?.cause_aggregate.aggregate?.count;
      break;
    case 'consequence':
      count = consequenceData?.consequence_aggregate.aggregate?.count;
      break;
    case 'risk':
      count = riskData?.risk_aggregate.aggregate?.count;
      break;
    case 'acceptance':
      count = acceptanceData?.acceptance_aggregate?.aggregate?.count;
      break;
    case 'appetite':
      count = appetiteData?.risk_aggregate.aggregate?.count;
      break;
    case 'impact':
      count = impactData?.impact_aggregate.aggregate?.count;
      break;
    case 'impactRating':
      count = impactRatingData?.impact_rating_aggregate.aggregate?.count;
      break;
    case 'assessment':
      count = assessmentData?.assessment_aggregate.aggregate?.count;
      break;
    case 'internalAuditReport':
      count =
        internalAuditReportData?.internal_audit_report_aggregate.aggregate
          ?.count;
      break;
    case 'internalAuditReportResult':
      count =
        (internalAuditReportResultData?.document_assessment_result_aggregate
          .aggregate?.count ?? 0) +
        (internalAuditReportResultData?.obligation_assessment_result_aggregate
          .aggregate?.count ?? 0) +
        (internalAuditReportResultData?.risk_assessment_result_aggregate
          .aggregate?.count ?? 0);
      break;
    case 'complianceMonitoringAssessment':
      count =
        complianceMonitoringAssessmentData
          ?.compliance_monitoring_assessment_aggregate.aggregate?.count;
      break;
    case 'complianceMonitoringAssessmentResult':
      count =
        (complianceMonitoringAssessmentResultData
          ?.document_assessment_result_aggregate.aggregate?.count ?? 0) +
        (complianceMonitoringAssessmentResultData
          ?.obligation_assessment_result_aggregate.aggregate?.count ?? 0) +
        (complianceMonitoringAssessmentResultData
          ?.risk_assessment_result_aggregate.aggregate?.count ?? 0);
      break;
    case 'control':
      count = controlData?.control_aggregate.aggregate?.count;
      break;
    case 'controlGroup':
      count = controlGroupData?.control_group_aggregate.aggregate?.count;
      break;
    case 'testResult':
      count = testResultData?.test_result_aggregate.aggregate?.count;
      break;
    case 'assessmentResult':
      count =
        (assessmentResultData?.document_assessment_result_aggregate.aggregate
          ?.count ?? 0) +
        (assessmentResultData?.obligation_assessment_result_aggregate.aggregate
          ?.count ?? 0) +
        (assessmentResultData?.risk_assessment_result_aggregate.aggregate
          ?.count ?? 0);
      break;
  }

  return <Count count={count} badge={badge} isActive={isActive} />;
};

export default ConnectedCount;
