import { create } from 'zustand/index';

import { defaultQuestionData } from '../form-configs/question';
import type {
  CustomSchema,
  CustomUISchema,
  CustomUISchemaElement,
  FormBuilderAction,
  QuestionData,
} from '../types';

type FormBuilderState = {
  formData: unknown;
  setFormData: (formData: unknown) => void;
  schema: CustomSchema;
  setSchema: (schema: CustomSchema) => void;
  uiSchema: CustomUISchema;
  setUISchema: (uiSchema: CustomUISchema) => void;
  currentElementUISchema: CustomUISchema | CustomUISchemaElement;
  setCurrentElementUISchema: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => void;
  isFormCustomisable: boolean;
  setIsFormCustomisable: (isFormCustomisable: boolean) => void;
  isFormDirty: boolean;
  setIsFormDirty: (isFormDirty: boolean) => void;
  isCustomising: boolean;
  setIsCustomising: (isCustomising: boolean) => void;
  isEditingQuestion: boolean;
  setIsEditingQuestion: (isEditingQuestion: boolean) => void;
  formQuestionModalAction: FormBuilderAction | null;
  setFormQuestionModalAction: (action: FormBuilderAction | null) => void;
  questionData: QuestionData;
  setQuestionData: (questionData: QuestionData) => void;
  parentId: string;
  setParentId: (parentId: string) => void;
};

export const useFormBuilderStore = create<FormBuilderState>((set) => ({
  formData: {},
  setFormData: (formData: unknown) => set({ formData }),

  schema: {
    type: 'object',
    properties: {},
    required: [],
  },
  setSchema: (schema: CustomSchema) => set({ schema }),

  uiSchema: {
    type: 'VerticalLayout',
    elements: [],
  },
  setUISchema: (uiSchema: CustomUISchema) => set({ uiSchema }),

  currentElementUISchema: {} as CustomUISchema | CustomUISchemaElement,
  setCurrentElementUISchema: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement
  ) => set({ currentElementUISchema }),

  isFormCustomisable: false,
  setIsFormCustomisable: (isFormCustomisable: boolean) =>
    set({ isFormCustomisable }),

  isCustomising: false,
  setIsCustomising: (isCustomising: boolean) => set({ isCustomising }),

  parentId: '',
  setParentId: (parentId: string) => set({ parentId }),

  isEditingQuestion: false,
  setIsEditingQuestion: (isEditingQuestion: boolean) =>
    set({ isEditingQuestion }),

  formQuestionModalAction: null,
  setFormQuestionModalAction: (
    formQuestionModalAction: FormBuilderAction | null
  ) => set({ formQuestionModalAction }),

  questionData: defaultQuestionData,
  setQuestionData: (questionData: QuestionData) => set({ questionData }),

  isFormDirty: false,
  setIsFormDirty: (isFormDirty: boolean) => set({ isFormDirty }),
}));
