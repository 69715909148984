import Modal from '@cloudscape-design/components/modal';
import SpaceBetween from '@cloudscape-design/components/space-between';
import TextContent from '@cloudscape-design/components/text-content';
import { JsonForms } from '@jsonforms/react';
import type { ErrorObject } from 'ajv';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import Button from '../Button';
import DeleteButton from '../DeleteButton';
import DeleteModal from '../DeleteModal';
import { questionUISchema, useQuestionSchema } from './form-configs/question';
import rendererRegistry from './renderers/registry';
import { useFormBuilderQuestionStore } from './store/useFormBuilderQuestionStore';
import { useFormBuilderStore } from './store/useFormBuilderStore';
import { FieldOptionType, FormBuilderAction } from './types';

export const FormQuestionModal: FC = () => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>(undefined);
  const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([]);

  const questionSchema = useQuestionSchema();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formQuestion',
  });
  const {
    isFormCustomisable,
    formQuestionModalAction,
    isEditingQuestion,
    setIsEditingQuestion,
    parentId,
    questionData,
    setQuestionData,
    currentElementUISchema,
    setIsFormDirty,
  } = useFormBuilderStore(
    useShallow((state) => ({
      isFormCustomisable: state.isFormCustomisable,
      formQuestionModalAction: state.formQuestionModalAction,
      isEditingQuestion: state.isEditingQuestion,
      setIsEditingQuestion: state.setIsEditingQuestion,
      parentId: state.parentId,
      questionData: state.questionData,
      setQuestionData: state.setQuestionData,
      currentElementUISchema: state.currentElementUISchema,
      setIsFormDirty: state.setIsFormDirty,
    }))
  );

  const { addNewQuestion, updateQuestion, deleteQuestion } =
    useFormBuilderQuestionStore(
      useShallow((state) => ({
        addNewQuestion: state.addNewQuestion,
        updateQuestion: state.updateQuestion,
        deleteQuestion: state.deleteQuestion,
      }))
    );

  const resetModal = () => {
    setAdditionalErrors([]);
    setIsEditingQuestion(false);
    setIsFormDirty(false);
  };

  const handleEditQuestionCancel = () => {
    resetModal();
  };

  const handleAddNewQuestion = () => {
    addNewQuestion(questionData, parentId);
    resetModal();
  };

  const handleAdditionalErrors = useCallback(() => {
    setAdditionalErrors([]);

    if (
      (questionData.fieldType === FieldOptionType.Dropdown ||
        questionData.fieldType === FieldOptionType.Multiselect) &&
      !questionData?.selectOptions?.length
    ) {
      const newError: ErrorObject = {
        instancePath: '/selectOptions',
        message: 'At least one option is required for a dropdown field',
        schemaPath: '',
        keyword: '',
        params: {},
      };

      setAdditionalErrors((additionalErrors) => [
        ...additionalErrors,
        newError,
      ]);
    }
  }, [questionData]);

  useEffect(() => {
    handleAdditionalErrors();
  }, [questionData, handleAdditionalErrors]);

  useEffect(() => {
    if (!errors?.length && !additionalErrors?.length) {
      setIsFormDirty(false);
    }
  }, [handleAdditionalErrors, errors, additionalErrors, setIsFormDirty]);

  const handleUpdateQuestion = () => {
    updateQuestion(questionData, currentElementUISchema, parentId);
    resetModal();
  };

  const handleSaveQuestion = () => {
    setIsFormDirty(true);
    handleAdditionalErrors();

    if (errors?.length || additionalErrors?.length) {
      return;
    }

    if (formQuestionModalAction === FormBuilderAction.Add) {
      handleAddNewQuestion();
    }

    if (formQuestionModalAction === FormBuilderAction.Edit) {
      handleUpdateQuestion();
    }
  };

  const handleDeleteQuestion = () => {
    deleteQuestion(currentElementUISchema, parentId);
    resetModal();
  };

  return (
    <>
      {isEditingQuestion ? (
        <>
          <Modal
            onDismiss={resetModal}
            visible={isEditingQuestion}
            header={
              formQuestionModalAction === FormBuilderAction.Add
                ? t('addQuestionModalTitle')
                : t('editQuestionModalTitle')
            }
            footer={
              <div className={'flex justify-between'}>
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="primary" onClick={handleSaveQuestion}>
                    {t('saveButtonLabel')}
                  </Button>
                  <Button variant="normal" onClick={handleEditQuestionCancel}>
                    {t('cancelButtonLabel')}
                  </Button>
                </SpaceBetween>
                {formQuestionModalAction === FormBuilderAction.Edit ? (
                  <DeleteButton onClick={() => setIsDeleteModalVisible(true)}>
                    {t('deleteButtonLabel')}
                  </DeleteButton>
                ) : null}
              </div>
            }
          >
            {/*
              Avoid spreading data into Json Forms as it is a
              known anti-pattern that causes infinite render loops
              https://jsonforms.io/faq/#how-can-i-minimize-re-rendering
            */}
            <JsonForms
              data={questionData}
              readonly={!isFormCustomisable}
              schema={questionSchema}
              uischema={questionUISchema}
              renderers={rendererRegistry}
              additionalErrors={additionalErrors}
              onChange={({ data, errors }) => {
                const formattedErrors = errors?.map((error) => ({
                  ...error,
                  dataPath: '',
                })) as ErrorObject[] | undefined;

                setErrors(formattedErrors);
                setQuestionData(data);
              }}
            />
          </Modal>

          <DeleteModal
            loading={false}
            isVisible={isDeleteModalVisible}
            onDelete={handleDeleteQuestion}
            onDismiss={() => setIsDeleteModalVisible(false)}
            header={t('deleteModal.header')}
          >
            <TextContent>
              <p className={'whitespace-pre-wrap'}>{t('deleteModal.body')}</p>
            </TextContent>
          </DeleteModal>
        </>
      ) : null}
    </>
  );
};
