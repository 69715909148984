import type { JsonSchema7 } from '@jsonforms/core';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand/index';

import { handleError } from '../../utils/errorUtils';
import type {
  CustomSchema,
  CustomUISchema,
  CustomUISchemaElement,
  QuestionData,
} from '../types';
import { FieldOptionType } from '../types';
import { excludeRequiredPropertyFromRequiredList } from './sharedUtils';
import { useFormBuilderStore } from './useFormBuilderStore';

type FormBuilderQuestionState = {
  addNewQuestion: (questionData: QuestionData, parentId: string) => void;
  updateQuestion: (
    questionData: QuestionData,
    currentElementUISchema: CustomUISchema | CustomUISchemaElement,
    parentId: string
  ) => void;
  deleteQuestion: (
    currentElementUISchema: CustomUISchema | CustomUISchemaElement,
    parentId: string
  ) => void;
};

const addOrUpdateQuestionData = (questionData: QuestionData) => {
  return {
    ...(questionData.fieldType === FieldOptionType.Multiselect
      ? {
          type: 'array',
          uniqueItems: true,
          minItems: questionData.isPropertyRequired ? 1 : 0,
          ...(questionData.selectOptions?.length && {
            items: {
              oneOf: questionData.selectOptions.map((option) => ({
                const: option.generatedId,
                title: option.value,
              })),
            },
          }),
        }
      : {
          type: 'string',
          minLength: questionData.isPropertyRequired ? 1 : 0,
          ...(questionData.fieldType === FieldOptionType.Dropdown &&
            questionData.selectOptions?.length && {
              oneOf: questionData.selectOptions.map((option) => ({
                const: option.generatedId,
                title: option.value,
              })),
            }),
        }),
  };
};

export const useFormBuilderQuestionStore = create<FormBuilderQuestionState>(
  () => ({
    addNewQuestion: (questionData: QuestionData, parentId: string) => {
      const schema = useFormBuilderStore.getState().schema;
      const setSchema = useFormBuilderStore.getState().setSchema;
      const uiSchema = useFormBuilderStore.getState().uiSchema;
      const setUISchema = useFormBuilderStore.getState().setUISchema;

      const uuid = `question_${uuidv4()}`;

      // Add new question to the schema
      setSchema({
        ...schema,
        properties: {
          ...schema.properties,
          [uuid]: {
            parentId,
            isCustomisable: true,
            allowAttachments: questionData.allowAttachments,
            ...addOrUpdateQuestionData(questionData),
          },
        },
        required: [
          ...(schema.required || []),
          ...(questionData.isPropertyRequired ? [uuid] : []),
        ],
      } as CustomSchema);

      // Iterate through all the sections (found in the elements array of the UI Schema)
      // and add the question to the section being edited
      const modifiedUISchemaElementsCopy = uiSchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === parentId) {
            return {
              ...element,
              elements: [
                ...(element?.elements || []),
                {
                  type: 'Control',
                  label: questionData.questionTitle,
                  id: uuid,
                  parentId,
                  scope: `#/properties/${uuid}`,
                  options: {
                    fieldType: questionData.fieldType,
                    placeholder: questionData.placeholder,
                    description: questionData.description,
                  },
                },
              ],
            };
          }

          return element;
        }
      );

      // Update the UI Schema with the list of modified sections
      setUISchema({
        ...uiSchema,
        elements: modifiedUISchemaElementsCopy,
      });
    },

    updateQuestion: (
      questionData: QuestionData,
      currentElementUISchema: CustomUISchema | CustomUISchemaElement,
      parentId: string
    ) => {
      const schema = useFormBuilderStore.getState().schema;
      const setSchema = useFormBuilderStore.getState().setSchema;
      const uiSchema = useFormBuilderStore.getState().uiSchema;
      const setUISchema = useFormBuilderStore.getState().setUISchema;

      if (!currentElementUISchema?.id) {
        handleError(
          new Error('useFormBuilderQuestionStore: No id found in uiSchema')
        );

        return;
      }

      if (!schema?.properties) {
        handleError(
          new Error(
            'useFormBuilderQuestionStore: No properties found in schema'
          )
        );

        return;
      }

      // Update the required list to exclude the updated question
      const updatedRequiredList =
        schema.required?.filter(
          (fieldId) => fieldId !== currentElementUISchema.id
        ) || [];

      // Add this question to the required list if it is required
      if (questionData.isPropertyRequired) {
        updatedRequiredList.push(currentElementUISchema.id);
      }

      // Update the schema with the modified question and update required list
      setSchema({
        ...schema,
        properties: {
          ...schema.properties,
          [currentElementUISchema.id]: {
            ...(schema.properties &&
              schema.properties[currentElementUISchema.id]),
            allowAttachments: questionData.allowAttachments,
            ...addOrUpdateQuestionData(questionData),
          },
        },
        required: updatedRequiredList,
      } as CustomSchema);

      // Iterate through all the sections (found in the elements array of the UI Schema)
      const modifiedUISchemaElementsCopy = uiSchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === parentId) {
            const modifiedElements = element?.elements
              ? element.elements.map((childElement: CustomUISchemaElement) => {
                  // Update the question that matches the question being edited
                  if (childElement.id === currentElementUISchema?.id) {
                    return {
                      ...childElement,
                      label: questionData.questionTitle,
                      options: {
                        fieldType: questionData.fieldType,
                        placeholder: questionData.placeholder,
                        description: questionData.description,
                      },
                    };
                  }

                  // Otherwise return the question as is
                  return childElement;
                })
              : [];

            return {
              ...element,
              elements: modifiedElements,
            };
          }

          return element;
        }
      );

      // Update the UI Schema with the list of modified sections
      setUISchema({
        ...uiSchema,
        elements: modifiedUISchemaElementsCopy,
      });
    },

    deleteQuestion: (
      currentElementUISchema: CustomUISchema | CustomUISchemaElement,
      parentId: string
    ) => {
      const schema = useFormBuilderStore.getState().schema;
      const setSchema = useFormBuilderStore.getState().setSchema;
      const uiSchema = useFormBuilderStore.getState().uiSchema;
      const setUISchema = useFormBuilderStore.getState().setUISchema;

      if (!currentElementUISchema?.id) {
        handleError(
          new Error('useFormBuilderQuestionStore: No id found in uiSchema')
        );

        return;
      }

      if (!schema?.properties) {
        handleError(
          new Error(
            'useFormBuilderQuestionStore: No properties found in schema'
          )
        );

        return;
      }

      // Iterate through all the sections (found in the elements array of the UI Schema)
      const modifiedUISchemaElementsCopy = uiSchema.elements.map(
        (element: CustomUISchemaElement) => {
          if (element.id === parentId) {
            // Filter out the question that matches the question being deleted
            const filteredElements = element?.elements
              ? element.elements.filter(
                  (elementElement: CustomUISchemaElement) =>
                    elementElement.id !== currentElementUISchema.id
                )
              : [];

            return {
              ...element,
              elements: filteredElements,
            };
          }

          return element;
        }
      );

      // Update the UI Schema with the list of modified sections
      setUISchema({
        ...uiSchema,
        elements: modifiedUISchemaElementsCopy,
      });

      // Update the schema to remove the question that matches the question being deleted
      const modifiedSchemaPropertiesCopy = Object.keys(
        schema.properties || {}
      ).reduce(
        (acc, key) => {
          if (key !== currentElementUISchema.id) {
            acc![key] = schema.properties![key];
          }

          return acc;
        },
        {} as JsonSchema7['properties']
      );

      // Update the schema with the list of questions (excluding the deleted question)
      setSchema({
        ...schema,
        properties: { ...modifiedSchemaPropertiesCopy },
        required: excludeRequiredPropertyFromRequiredList({
          propertyIds: Object.keys(modifiedSchemaPropertiesCopy || {}),
          uiSchemaId: currentElementUISchema.id,
        }),
      });
    },
  })
);
