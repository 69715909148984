import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { Header } from '../../theme/risksmart-themed/components';
import { defaultQuestionData } from './form-configs/question';
import { useFormBuilderStore } from './store/useFormBuilderStore';
import { FormBuilderAction } from './types';

type FormBuilderAddSectionProps = {
  parentId: string;
};

export const FormBuilderAddQuestion: FC<FormBuilderAddSectionProps> = ({
  parentId,
}) => {
  const {
    uiSchema,
    setFormQuestionModalAction,
    setIsEditingQuestion,
    setCurrentElementUISchema,
    setQuestionData,
    setParentId,
  } = useFormBuilderStore(
    useShallow((state) => ({
      uiSchema: state.uiSchema,
      setFormQuestionModalAction: state.setFormQuestionModalAction,
      setIsEditingQuestion: state.setIsEditingQuestion,
      setCurrentElementUISchema: state.setCurrentElementUISchema,
      setQuestionData: state.setQuestionData,
      setParentId: state.setParentId,
    }))
  );

  const { t } = useTranslation(['common'], {
    keyPrefix: 'formBuilder.formQuestion',
  });

  return (
    <>
      <div
        className={
          'flex w-max p-3 transition hover:cursor-pointer hover:bg-grey150 rounded-md'
        }
        onClick={() => {
          setFormQuestionModalAction(FormBuilderAction.Add);
          setParentId(parentId);
          setCurrentElementUISchema(uiSchema);
          setQuestionData(defaultQuestionData);
          setIsEditingQuestion(true);
        }}
      >
        <Header variant={'h3'}>{t('addQuestionButtonLabel')}</Header>
      </div>
    </>
  );
};
