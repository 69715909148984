import type { issues as sharedDataset } from '@risksmart-app/shared/reporting/datasets/issues';

import type { FrontEndDataSource } from './types';

export const issues: FrontEndDataSource<typeof sharedDataset> = {
  label: 'Issues',

  fields: {
    title: { displayType: 'text', label: 'Title' },
    id: { displayType: 'text', label: 'Id' },
  },
};
