import { actions } from './actions';
import { controls } from './controls';
import { issues } from './issues';
import { risks } from './risks';
import type { FrontEndDataSources } from './types';

export const frontendDataSources: FrontEndDataSources = {
  risks,
  actions,
  issues,
  controls,
};
