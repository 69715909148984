import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useRiskScoreFormatters } from '@/hooks/useRiskScore';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import type { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import type { AssessmentTypeEnum } from '../../../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../../../useAssessmentTypeConfig';
import type {
  AssessmentResultFields,
  AssessmentResultRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  assessmentMode: AssessmentTypeEnum
): TableFields<AssessmentResultRegisterFields> => {
  const { t } = useTranslation('common', {
    keyPrefix: 'assessmentResults.columns',
  });
  const { getByValue: getByResultValue } = useRating('performance_result');
  const { getByValue: getEffectivenessByValue } = useRating('effectiveness');
  const getFormatters = useRiskScoreFormatters();
  const ratingFns = {
    document_assessment_result: (d: AssessmentResultFields) =>
      getByResultValue(d.Rating),
    obligation_assessment_result: (d: AssessmentResultFields) =>
      getByResultValue(d.Rating),
    test_result: (d: AssessmentResultFields) =>
      getEffectivenessByValue(d.OverallEffectiveness),
  };
  const {
    routing: { resultsEditUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  return {
    TypeLabelled: {
      header: t('Type'),
      cell: (item) => {
        return (
          <Link
            variant="secondary"
            href={resultsEditUrl(item.AssessmentId, item.Id)}
          >
            {item.TypeLabelled}
          </Link>
        );
      },
      isRowHeader: true,
    },
    ParentTitle: {
      header: t('Item'),
    },
    RatingLabelled: {
      header: t('Result'),
      cell: (item) => {
        if (item.typename === 'risk_assessment_result') {
          const { getInherentRatingBadge, getResidualRatingBadge } =
            getFormatters({
              inherentRating: item.Rating,
              residualRating: item.Rating,
            });
          if (
            item.ControlType ===
            Risk_Assessment_Result_Control_Type_Enum.Controlled
          ) {
            return getResidualRatingBadge();
          }

          return getInherentRatingBadge();
        }

        return (
          <SimpleRatingBadge
            rating={ratingFns[item.typename as keyof typeof ratingFns](item)}
          />
        );
      },
      sortingField: 'Rating',
    },
    TestDate: dateColumn(t('TestDate'), 'TestDate'),
    Rationale: {
      header: t('Rationale'),
    },
  };
};

export const useGetCollectionStatelessTableProps = (
  records: AssessmentResultFields[] | undefined,
  assessmentMode: AssessmentTypeEnum
): TablePropsWithActions<AssessmentResultRegisterFields> => {
  const { t: at } = useTranslation('common', {
    keyPrefix: 'assessmentResults',
  });

  const labelledFields = useLabelledFields(records);
  const fields = useGetFieldConfig(assessmentMode);

  return useGetTablePropsWithoutUrlHash({
    data: labelledFields,
    entityLabel: at('entity_name'),
    emptyCollectionAction: <></>,
    preferencesStorageKey: 'AssessmentResults-Preferences',
    enableFiltering: true,
    initialColumns: [
      'TestDate',
      'ParentTitle',
      'TypeLabelled',
      'RatingLabelled',
    ],
    fields,
  });
};
