import type {
  JsonFormsRendererRegistryEntry,
  RankedTester,
} from '@jsonforms/core';
import { and } from '@jsonforms/core';
import { isArrayObjectControl } from '@jsonforms/core';
import {
  isBooleanControl,
  isOneOfControl,
  isStringControl,
  rankWith,
  uiTypeIs,
} from '@jsonforms/core';

import { FieldOptionType } from '../types';
import { AddOptionControl } from './controls/AddOptionControl';
import { BooleanControl } from './controls/BooleanControl';
import { DateControl } from './controls/DateControl';
import { DropdownMultiselectControl } from './controls/DropdownMultiSelectControl';
import { DropdownSelectControl } from './controls/DropdownSelectControl';
import { TextAreaControl } from './controls/TextAreaControl';
import { TextControl } from './controls/TextControl';
import { GroupLayoutRenderer } from './layouts/GroupLayout';
import { VerticalLayoutRenderer } from './layouts/VerticalLayout';

/*
RATIONALE:
  - `rankWith` expects an integer to determine the priority that each tester is picked with
  - The higher the number, the earlier this tester is used to determine which renderer to use
  - Generally speaking, rules with higher specificity should be given a higher priority
    as lower numbers will be used as fall back renderers
  - The default value used for `rankWith` is 2 so any custom renderers should have a minimum rank of 3
*/

const dropdownMultiselectControlTester: RankedTester = rankWith(
  6,
  (uischema) => {
    return uischema.options?.fieldType === FieldOptionType.Multiselect;
  }
);

const dropdownSelectControlTester: RankedTester = rankWith(5, isOneOfControl);

const textAreaControlTester: RankedTester = rankWith(
  4,
  and(isStringControl, (uischema) => {
    return uischema.options?.fieldType === FieldOptionType.TextArea;
  })
);

const dateControlTester: RankedTester = rankWith(
  4,
  and(isStringControl, (uischema) => {
    return uischema.options?.fieldType === FieldOptionType.Date;
  })
);

const verticalLayoutTester: RankedTester = rankWith(
  3,
  uiTypeIs('VerticalLayout')
);

const groupLayoutTester: RankedTester = rankWith(3, uiTypeIs('Group'));

const textControlTester: RankedTester = rankWith(3, isStringControl);

const booleanControlTester: RankedTester = rankWith(3, isBooleanControl);

const addOptionControlTester: RankedTester = rankWith(3, isArrayObjectControl);

const rendererRegistry: JsonFormsRendererRegistryEntry[] = [
  {
    tester: verticalLayoutTester,
    renderer: VerticalLayoutRenderer,
  },
  {
    tester: groupLayoutTester,
    renderer: GroupLayoutRenderer,
  },
  {
    tester: textControlTester,
    renderer: TextControl,
  },
  {
    tester: textAreaControlTester,
    renderer: TextAreaControl,
  },
  {
    tester: dateControlTester,
    renderer: DateControl,
  },
  {
    tester: booleanControlTester,
    renderer: BooleanControl,
  },
  {
    tester: dropdownSelectControlTester,
    renderer: DropdownSelectControl,
  },
  {
    tester: dropdownMultiselectControlTester,
    renderer: DropdownMultiselectControl,
  },
  {
    tester: addOptionControlTester,
    renderer: AddOptionControl,
  },
];

export default rendererRegistry;
