import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

import type {
  EnterpriseRiskFields,
  EnterpriseRiskRegisterFields,
} from './types';

export const useLabelledFields = (
  data: EnterpriseRiskFields[] | undefined
): EnterpriseRiskRegisterFields[] => {
  const { t } = useTranslation(['common']);
  const tiers = useMemo(() => t('tiers', { returnObjects: true }), [t]);
  const treatments = useMemo(
    () => t('treatments', { returnObjects: true }),
    [t]
  );

  return useMemo(() => {
    return (
      data?.map((record) => ({
        ...record,
        SequentialIdLabelled: record.SequentialId
          ? getFriendlyId(Parent_Type_Enum.EnterpriseRisk, record.SequentialId)
          : '-',
        TierLabelled: tiers[String(record.Tier) as keyof typeof tiers],
        TreatmentLabelled:
          treatments[String(record.Treatment) as keyof typeof treatments],
        ParentTitle: record.parent?.Title ?? '-',
        CreatedByUser: record.createdByUser?.FriendlyName ?? '-',
        ModifiedByUser: record.modifiedByUser?.FriendlyName ?? '-',
        Description: record.Description ?? '-',
      })) ?? []
    );
  }, [data, tiers, treatments]);
};
