import type {
  ControlProps,
  JsonSchema7,
  Labelable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';

export enum FormBuilderAction {
  Add = 'add',
  Edit = 'edit',
}

export type FieldOption = {
  value: string;
  generatedId: string;
};

export enum FieldOptionType {
  Text = 'text',
  TextArea = 'textArea',
  Number = 'number',
  Url = 'url',
  Date = 'date',
  Dropdown = 'dropdown',
  Multiselect = 'multiselect',
}

export interface SectionData {
  sectionTitle: string;
}

export interface QuestionData {
  questionTitle: string;
  placeholder?: string;
  description?: string;
  fieldType: FieldOptionType;
  selectOptions?: { value: string; generatedId: string }[];
  isPropertyRequired: boolean;
  allowAttachments: boolean;
  files?: File[];
}

export interface CustomSchemaProperties extends JsonSchema7 {
  parentId?: string;
}

export type CustomSchema = JsonSchema7 & {
  properties?: CustomSchemaProperties;
  parentId?: string;
  isCustomisable?: boolean;
  isPropertyRequired?: boolean;
  allowAttachments?: boolean;
  items?: {
    oneOf?: JsonSchema7[];
  };
};

export type CustomUISchemaElement = UISchemaElement &
  Labelable & {
    id: string;
    parentId?: string;
    elements?: CustomUISchemaElement[];
  };

export type ExtendedControlProps = ControlProps & {
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
};

export type CustomUISchema = VerticalLayout & {
  id?: string;
  elements: CustomUISchemaElement[];
};
