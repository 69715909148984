import Alert from '@cloudscape-design/components/alert';
import _ from 'lodash';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'src/layouts/PageLayout';

import Loading from '@/components/Loading';
import { useGetReportingDataLazyQuery } from '@/generated/graphql';

import type { ReportDefinitionFormData } from './definitionSchema';
import { ReportDefinitionForm } from './ReportDefinitionForm';
import ReportTable from './ReportTable';
import { getFlattenedDataSources } from './utils';

const Page: FC = () => {
  const { t } = useTranslation(['common'], { keyPrefix: 'reports' });
  const [definitionFormData, setDefinitionFormData] =
    useState<ReportDefinitionFormData>();
  const [getReportData, getReportDataResult] = useGetReportingDataLazyQuery();

  return (
    <PageLayout
      title={t('reports_title')}
      secondary={
        <ReportDefinitionForm
          values={definitionFormData}
          onSave={async (formData) => {
            setDefinitionFormData(formData);

            const dataSources = getFlattenedDataSources(formData.dataSource);
            const fields = formData.fields;

            getReportData({
              variables: {
                Input: {
                  dataSources,
                  fields: fields.map((f) => ({
                    fieldId: f.fieldId,
                    dataSourceIndex: f.dataSourceIndex,
                  })),
                  filters: formData.filters.tokens.map((t) => {
                    const keyParts = t.propertyKey.split('|');

                    return {
                      value: t.value as unknown as string,
                      operator: t.operator,
                      field: {
                        dataSourceIndex: parseInt(keyParts[0]),
                        fieldId: keyParts[1],
                      },
                    };
                  }),
                },
              },
            });
          }}
        />
      }
    >
      {getReportDataResult.error && (
        <Alert type="error">Failed to retrieve report</Alert>
      )}
      {getReportDataResult.loading && <Loading />}
      {/* columns can change, so hide table whilst loading */}
      {!getReportDataResult.loading && (
        <ReportTable
          definition={definitionFormData}
          items={getReportDataResult.data?.reportingData}
        />
      )}
    </PageLayout>
  );
};

export default Page;
