import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledMultiselect from '@/components/Form/ControlledMultiselect';

import DatasourceTree from './DatasourceTree';
import {
  defaultValues,
  type ReportDefinitionFormData,
} from './definitionSchema';
import ReportPropertyFilterPanel from './ReportPropertyFilterPanel';
import { getAllowedFields } from './utils';

export const ReportDefinitionFormFields = () => {
  const { control, watch, setValue } =
    useFormContext<ReportDefinitionFormData>();
  const dataSource = watch('dataSource');
  const allFields = getAllowedFields(dataSource);
  const { t } = useTranslation(['common'], { keyPrefix: 'reports.fields' });

  return (
    <>
      <DatasourceTree
        testId="dataSource"
        name="dataSource"
        onChange={() => {
          setValue('fields', defaultValues.fields);
          setValue('filters', defaultValues.filters);
        }}
      />
      {dataSource.type && (
        <ControlledMultiselect
          control={control}
          label={t('fields')}
          name="fields"
          filteringType="auto"
          testId="fields"
          hideTokens={false}
          options={allFields}
        />
      )}
      {allFields.length > 0 && (
        <ReportPropertyFilterPanel allFields={allFields} />
      )}
    </>
  );
};
