import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs/ControlledTabs';

import { useTabs } from './useTabs';

type Props = {
  activeTabId?:
    | 'audit'
    | 'tags'
    | 'departments'
    | 'groups'
    | 'users'
    | 'approvals'
    | 'taxonomy'
    | 'dataImport'
    | 'authentication';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const { t, i18n } = useTranslation(['taxonomy', 'common']);

  const tabs = useTabs();
  const title = i18n.format(t('setting_other'), 'capitalize');

  return (
    <PageLayout title={title}>
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
    </PageLayout>
  );
};

export default Page;
