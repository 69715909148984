import type { PropertyFilterOperatorFormProps } from '@cloudscape-design/collection-hooks';
import FormField from '@cloudscape-design/components/form-field';
import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import Select from '@cloudscape-design/components/select';
import type { FC } from 'react';

const SelectFilter: FC<
  PropertyFilterOperatorFormProps<string> & { options: OptionDefinition[] }
> = ({ value, onChange, options }) => {
  return (
    <FormField>
      <Select
        expandToViewport={true}
        virtualScroll={true}
        options={options}
        selectedOption={options.find((o) => o.value === value) ?? null}
        onChange={(event) => onChange(event.detail.selectedOption.value ?? '')}
      />
    </FormField>
  );
};

export default SelectFilter;
