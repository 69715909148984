import type { controls as sharedDataset } from '@risksmart-app/shared/reporting/datasets/controls';

import type { FrontEndDataSource } from './types';

export const controls: FrontEndDataSource<typeof sharedDataset> = {
  label: 'Controls',
  fields: {
    title: { displayType: 'text', label: 'Title' },
    id: { displayType: 'text', label: 'Id' },
  },
};
