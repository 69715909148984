import { useCollection } from '@cloudscape-design/collection-hooks';
import Grid from '@cloudscape-design/components/grid';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { defaultPropertyFilterI18nStrings } from '@risksmart-app/components/Table/propertyFilterI18nStrings';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import PageFilterContainer from '@/components/PageFilterContainer/PageFilterContainer';
import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import { useGetEnterpriseRisksFlatQuery } from '@/generated/graphql';
import { useAddCustomAttributes } from '@/utils/table/hooks/useAddCustomAttributes';
import { useCreateFilterOptions } from '@/utils/table/hooks/useCreateFilterOptions';
import { useCreateFilterProperties } from '@/utils/table/hooks/useCreateFilterProperties';
import { addEnterpriseRiskUrl } from '@/utils/urls';

import { useGetFieldConfig } from '../config';
import { useLabelledFields } from '../useLabelledFields';
import styles from './style.module.scss';
import Tier from './Tier';
import type { DashboardState } from './types';

const initialDashboardState = new Map<number, string | undefined>(
  Array.from({ length: 3 }, (_, i) => [i + 1, undefined])
);

const Page: FC = () => {
  useI18NSummaryHelpContent('enterpriseRisks.dashboardHelp');
  const { t } = useTranslation(['common'], {
    keyPrefix: 'enterpriseRisks',
  });
  const title = t('dashboardTitle');

  const [dashboardState, setDashboardState] = useState<DashboardState>(
    initialDashboardState
  );
  const fields = useGetFieldConfig();
  const { addNotification } = useNotifications();
  const { data } = useGetEnterpriseRisksFlatQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
    onCompleted: (data) => {
      // Select the first tier 1 risk
      const firstTier1Risk = data.enterprise_risk.find((r) => r.Tier === 1);
      if (firstTier1Risk) {
        initialDashboardState.set(1, firstTier1Risk?.Id);
        setDashboardState(initialDashboardState);
      }
    },
  });

  const labelledFields = useLabelledFields(data?.enterprise_risk);
  const { tableFields, tableData } = useAddCustomAttributes({
    fields,
    data: labelledFields,
    customAttributeSchema:
      data?.form_configuration?.[0]?.customAttributeSchema ?? null,
    useRelativeDates: true,
  });
  const filteringProperties = useCreateFilterProperties(tableFields);

  const { items, propertyFilterProps } = useCollection(tableData, {
    propertyFiltering: {
      filteringProperties,
    },
    selection: {},
  });
  const fixedFilterOptions = useCreateFilterOptions(
    tableFields,
    tableData,
    propertyFilterProps.filteringOptions
  );

  return (
    <PageLayout
      actions={
        <Permission permission="insert:risk" canHaveAccessAsContributor={true}>
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" href={addEnterpriseRiskUrl()}>
              {t('createNewButton')}
            </Button>
          </SpaceBetween>
        </Permission>
      }
      title={title}
    >
      <PageFilterContainer>
        <div className="flex w-full space-x-2">
          <div className="grow">
            <PropertyFilterPanel
              {...propertyFilterProps}
              filteringOptions={fixedFilterOptions}
              i18nStrings={defaultPropertyFilterI18nStrings}
              virtualScroll={true}
            />
          </div>
        </div>
      </PageFilterContainer>
      <div className={styles.dashboard}>
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
          <Tier
            tier={1}
            tierRisks={items}
            dashboardState={dashboardState}
            setDashboardState={setDashboardState}
          />
          <Tier
            tier={2}
            tierRisks={items}
            dashboardState={dashboardState}
            setDashboardState={setDashboardState}
          />
          <Tier
            tier={3}
            tierRisks={items}
            dashboardState={dashboardState}
            setDashboardState={setDashboardState}
          />
        </Grid>
      </div>
    </PageLayout>
  );
};

export default Page;
