import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import IssueForm from 'src/pages/issues/update/forms/IssueForm';
import type { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';
import { defaultValues } from 'src/pages/issues/update/forms/issueSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import type { ObjectWithContributors } from 'src/rbac/Permission';

import { ownerAndContributorIds } from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useGetIssueByIdQuery,
  useInsertChildIssueMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import type { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import { useAssessmentTypeConfig } from '../useAssessmentTypeConfig';

type Props = {
  assessmentMode: AssessmentTypeEnum;
  readonly: boolean;
  assessmentId: string;
  assessedItem?: ObjectWithContributors;
  id?: string;
  onDismiss?: (saved: boolean) => void;
  beforeFieldsSlot?: ReactNode;
  showAssessmentSelector?: boolean;
  header?: string;
};

const ConnectedIssueForm: FC<Props> = ({
  readonly,
  assessmentId,
  assessmentMode,
  id,
  onDismiss,
  header,
  beforeFieldsSlot,
}) => {
  const navigate = useNavigate();
  const { data } = useGetIssueByIdQuery({
    variables: {
      _eq: id!,
    },
    skip: !id,
  });
  const issue = data?.issue?.[0];
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const { updateFiles } = useFileUpdate();
  const [insertChildIssue] = useInsertChildIssueMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'assessment_by_pk');
    },
  });

  const onSave = async (values: IssueFormDataFields) => {
    const { files, newFiles } = values;
    const result = await insertChildIssue({
      variables: {
        ...values,
        CustomAttributeData: values.CustomAttributeData ?? null,
        ParentId: assessmentId,
        ...ownerAndContributorIds(values),
      },
    });

    if (!result.data?.insertChildIssue?.Id) {
      throw new Error('Issue id is missing');
    }

    await updateFiles({
      parentId: result.data?.insertChildIssue?.Id,
      parentType: Parent_Type_Enum.Issue,
      newFiles,
      selectedFiles: files,
      originalFiles: issue?.files ?? [],
    });
    navigate(resultsRegisterUrl(assessmentId));
  };

  return (
    <IssueForm
      beforeFieldsSlot={beforeFieldsSlot}
      header={header}
      onDismiss={onDismiss}
      onSave={onSave}
      values={{
        ...defaultValues,
        ...issue,
        Owners: getOwners(issue),
        Contributors: getContributors(issue),
        ancestorContributors: issue?.ancestorContributors ?? [],
      }}
      issueType={Parent_Type_Enum.Issue}
      readOnly={readonly}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default ConnectedIssueForm;
