import { useTranslation } from 'react-i18next';

import type { ScimTokenOutput } from '@/generated/graphql';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import type { TableFields } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

export type ScimDomainFields = {
  Domain: string;
  CreatedOn: string;
};

export const useGetScimDomainTableProps = (domains: ScimDomainFields[]) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'authenticationSettings.scimDomains',
  });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'authenticationSettings.scimDomains.columns',
  });
  const fields: TableFields<ScimDomainFields> = {
    Domain: {
      header: st('domain'),
    },
    CreatedOn: dateColumn(st('createdOn'), 'CreatedOn'),
  };

  return useGetTableProps({
    tableId: 'scimDomains',
    data: domains,
    entityLabel: t('entityName'),
    fields,
    initialColumns: ['Domain', 'CreatedOn'],
    preferencesStorageKey: 'ScimDomainSettingsTable-Preferences',
  });
};

export type ScimTokenFields = Omit<ScimTokenOutput, '__typename'>;

export const useGetScimTokenTableProps = (domains: ScimTokenFields[]) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'authenticationSettings.scimTokens',
  });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'authenticationSettings.scimTokens.columns',
  });
  const fields: TableFields<ScimTokenFields> = {
    keyId: {
      header: st('keyId'),
    },
    createdOn: dateColumn(st('createdOn'), 'createdOn', undefined, true),
    expiresOn: dateColumn(st('expiresOn'), 'expiresOn', undefined, true),
    status: {
      header: st('status'),
    },
  };

  return useGetTableProps({
    tableId: 'scimTokens',
    data: domains,
    entityLabel: t('entityName'),
    fields,
    initialColumns: ['keyId', 'createdOn', 'expiresOn', 'status'],
    preferencesStorageKey: 'ScimTokenSettingsTable-Preferences',
    defaultSortingState: {
      sortingColumn: 'status',
      sortingDirection: 'asc',
    },
  });
};
