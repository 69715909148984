import SpaceBetween from '@cloudscape-design/components/space-between';
import Toggle from '@cloudscape-design/components/toggle';
import { withJsonFormsControlProps } from '@jsonforms/react';
import type { FC } from 'react';

import type { ExtendedControlProps } from '../../types';
import Attachments from '../helpers/Attachments';
import style from './style.module.scss';

const BooleanControlUnwrapped: FC<ExtendedControlProps> = (props) => {
  const { data, handleChange, path, id, enabled, schema } = props;

  return (
    <SpaceBetween size={'xs'} direction={'horizontal'}>
      <div className={style.booleanCell}>
        <Toggle
          checked={!!data}
          onChange={(ev) => handleChange(path, ev.detail.checked)}
          name={id}
          disabled={!enabled}
        >
          {props.label}
        </Toggle>
        <Attachments
          path={path}
          handleChange={handleChange}
          allowAttachments={schema.allowAttachments}
          disabled={!enabled}
        />
      </div>
    </SpaceBetween>
  );
};

export const BooleanControl = withJsonFormsControlProps(
  // For more info on why this is ignored, see `Known Issues` in `@risksmart-app/docs/form-builder.md`
  // @ts-ignore
  BooleanControlUnwrapped
);
