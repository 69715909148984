import FormField from '@cloudscape-design/components/form-field';
import type { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import Select from '@cloudscape-design/components/select';
import Button from '@risksmart-app/components/Button';
import { getChildDataSources } from '@risksmart-app/shared/reporting/datasets/index';
import type { DataSourceType } from '@risksmart-app/shared/reporting/schema';
import type { FC } from 'react';
import type { UseFieldArrayRemove } from 'react-hook-form';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { frontendDataSources } from './datasets';

export type DatasourceProps = {
  index?: number;
  name: string;
  parentDatasourceId?: string;
  onRemove?: UseFieldArrayRemove;
  onChange: () => void;
  testId?: string;
};

const DatasourceTree: FC<DatasourceProps> = ({
  index,
  name,
  onRemove,
  parentDatasourceId,
  testId,
  onChange,
}) => {
  const { t } = useTranslation(['common'], { keyPrefix: 'reports.fields' });
  const { control, watch, setValue } = useFormContext();
  const { remove, append, fields } = useFieldArray({
    control,
    name: name + '.children',
  });
  const typeName = name + '.type';
  const type = watch(typeName);

  const dataSourceOptions: OptionDefinition[] = parentDatasourceId
    ? Array.from(getChildDataSources(parentDatasourceId as DataSourceType)).map(
        (datasourceId) => {
          const ds = frontendDataSources[datasourceId as DataSourceType];

          return { value: datasourceId, label: ds.label };
        }
      )
    : Object.keys(frontendDataSources).map((dsType) => {
        const ds = frontendDataSources[dsType as DataSourceType];

        return {
          label: ds.label,
          value: dsType,
        };
      });
  const selectedDatasource =
    frontendDataSources[type as DataSourceType] ?? null;

  return (
    <div>
      <FormField label={t('dataSource')} data-testid={'form-field-' + testId}>
        <div className="flex">
          <Select
            data-testid={`${testId}-select`}
            selectedOption={selectedDatasource}
            onChange={(e) => {
              setValue(typeName, e.detail.selectedOption.value);
              onChange();
            }}
            options={dataSourceOptions}
          />
          <div>
            {index !== undefined && onRemove && (
              <Button
                data-testid={`${testId}-remove`}
                iconName="remove"
                variant="inline-icon"
                onClick={() => {
                  onRemove(index);
                  onChange();
                }}
              />
            )}
          </div>
          {selectedDatasource && getChildDataSources(type).size > 0 && (
            <Button
              data-testid={`${testId}-add`}
              iconName="add-plus"
              variant="inline-icon"
              onClick={() => {
                append({});
                onChange();
              }}
            />
          )}
        </div>
      </FormField>

      <div className="ml-5 mt-3">
        {fields.map((f, nestedIndex) => (
          <DatasourceTree
            onChange={onChange}
            testId={`${testId}-${nestedIndex}`}
            onRemove={remove}
            key={f.id}
            index={nestedIndex}
            parentDatasourceId={type}
            name={`${name}.children.${nestedIndex}`}
          />
        ))}
      </div>
    </div>
  );
};

export default DatasourceTree;
