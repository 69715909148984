import FormField from '@cloudscape-design/components/form-field';
import Popover from '@cloudscape-design/components/popover';
import { InfoCircle } from '@untitled-ui/icons-react';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import FormEditButton from '../../../FormEditButton/FormEditButton';
import { useFormBuilderStore } from '../../store/useFormBuilderStore';
import type {
  CustomSchema,
  CustomUISchemaElement,
  QuestionData,
} from '../../types';
import { FieldOptionType, FormBuilderAction } from '../../types';
import { getFieldLabel } from './utils';

type CustomisableControlProps = {
  errors: string;
  schema: CustomSchema;
  uischema: CustomUISchemaElement;
  children: ReactNode | ReactNode[];
};

export const CustomisableControl: FC<CustomisableControlProps> = ({
  children,
  schema,
  uischema,
  errors,
}) => {
  const [label, setLabel] = useState<string>(uischema?.label || '');

  const {
    isFormCustomisable,
    isFormDirty,
    setFormQuestionModalAction,
    setIsEditingQuestion,
    setQuestionData,
    setParentId,
    setCurrentElementUISchema,
  } = useFormBuilderStore(
    useShallow((state) => ({
      isFormCustomisable: state.isFormCustomisable,
      isFormDirty: state.isFormDirty,
      setFormQuestionModalAction: state.setFormQuestionModalAction,
      setIsEditingQuestion: state.setIsEditingQuestion,
      setQuestionData: state.setQuestionData,
      setParentId: state.setParentId,
      setCurrentElementUISchema: state.setCurrentElementUISchema,
    }))
  );

  const setInitialSelectedOptions = () => {
    if (uischema?.options?.fieldType === FieldOptionType.Dropdown) {
      return (
        schema?.oneOf?.map((item) => {
          return {
            value: item?.title || '',
            generatedId: item?.const || '',
          };
        }) || []
      );
    }

    if (uischema?.options?.fieldType === FieldOptionType.Multiselect) {
      return (
        schema?.items?.oneOf?.map((item) => {
          return {
            value: item?.title || '',
            generatedId: item?.const || '',
          };
        }) || []
      );
    }

    return [];
  };

  const initialQuestionData: QuestionData = {
    questionTitle: uischema?.label ? `${uischema.label}` : '',
    description: uischema?.options?.description || '',
    placeholder: uischema?.options?.placeholder
      ? uischema.options.placeholder
      : '',
    fieldType: uischema?.options?.fieldType || 'text',
    selectOptions: setInitialSelectedOptions(),
    isPropertyRequired: (schema?.minLength && schema.minLength > 0) || false,
    allowAttachments: schema?.allowAttachments || false,
  };

  useEffect(() => {
    const isRequired =
      (schema?.minLength && schema.minLength > 0) ||
      (schema?.minItems && schema.minItems > 0) ||
      false;

    setLabel(getFieldLabel(uischema?.label, isRequired));
  }, [uischema, schema, setParentId]);

  const isCustomisable = isFormCustomisable && schema?.isCustomisable;

  return (
    <>
      <div className={'pb-6'}>
        <FormField
          label={label}
          stretch={true}
          errorText={isFormDirty ? errors : ''}
          info={
            uischema?.options?.description ? (
              <Popover
                dismissButton={true}
                size="large"
                triggerType="custom"
                content={uischema.options.description}
              >
                <button
                  type={'button'}
                  className={
                    'transition rounded-[18px] h-[18px] w-[18px] border-0 text-navy cursor-pointer ' +
                    'p-[0px] content-baseline flex justify-center items-center hover:bg-teal bg-[#ecfbfa]'
                  }
                  data-testid="field-guidance-button"
                >
                  <InfoCircle
                    viewBox="0 0 24 24"
                    className="w-[16px] h-[16px] relative"
                  />
                </button>
              </Popover>
            ) : null
          }
        >
          <div className={isCustomisable ? 'flex gap-x-4' : ''}>
            {children}

            {isCustomisable ? (
              <FormEditButton
                onClick={() => {
                  setFormQuestionModalAction(FormBuilderAction.Edit);
                  setParentId(uischema?.parentId || '');
                  setCurrentElementUISchema(uischema);
                  setQuestionData(initialQuestionData);
                  setIsEditingQuestion(true);
                }}
              />
            ) : null}
          </div>
        </FormField>
      </div>
    </>
  );
};
