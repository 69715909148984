import {
  DocumentUpdateBreadcrumb,
  PolicyFileCreatePage,
  PolicyFileUpdateBreadcrumb,
  PolicyFileUpdatePage,
  PolicyUpdatePage,
} from '@pages';
import i18n from '@risksmart-app/components/providers/i18n';
import type { RouteObject } from 'react-router';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

import { IssueTypeMapping } from '@/utils/issueVariantUtils';

import { actionRoute } from './actionRoutes.config';
import { assessmentsRoute } from './assessmentRoutes.config';
import { issueRoute } from './issueRoutes.config';

export const documentRoute: RouteObject = {
  path: ':documentId',
  handle: {
    breadcrumb: DocumentUpdateBreadcrumb,
  },
  children: [
    {
      index: true,
      element: (
        <OrgFeatureFlaggedRoute featureFlag="policy">
          <ProtectedRoute
            permission={'read:document'}
            canHaveAccessAsContributor={true}
          >
            <PolicyUpdatePage activeTabId="details" />
          </ProtectedRoute>
        </OrgFeatureFlaggedRoute>
      ),
    },
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <ProtectedRoute
                permission={'read:document'}
                canHaveAccessAsContributor={true}
              >
                <PolicyUpdatePage activeTabId="actions" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        actionRoute,
      ],
    },
    {
      path: 'ratings',
      handle: {
        title: () => i18n.format(i18n.t('assessment_other'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <ProtectedRoute
                permission={'read:document'}
                canHaveAccessAsContributor={true}
              >
                <PolicyUpdatePage activeTabId="ratings" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        assessmentsRoute,
      ],
    },
    {
      path: 'files',
      handle: {
        title: () => i18n.format(i18n.t('versions'), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <ProtectedRoute
                permission={'read:document'}
                canHaveAccessAsContributor={true}
              >
                <PolicyUpdatePage activeTabId="files" />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        {
          path: 'update/:id',
          handle: {
            breadcrumb: PolicyFileUpdateBreadcrumb,
          },
          children: [
            {
              index: true,
              element: (
                <OrgFeatureFlaggedRoute featureFlag="policy">
                  <PolicyFileUpdatePage activeTabId={'details'} />
                </OrgFeatureFlaggedRoute>
              ),
            },
            {
              path: 'attestations',
              element: (
                <OrgFeatureFlaggedRoute featureFlag="attestations">
                  <PolicyFileUpdatePage activeTabId={'attestations'} />
                </OrgFeatureFlaggedRoute>
              ),
            },
          ],
        },

        {
          path: 'create',
          handle: {
            title: () => i18n.format(i18n.t('create_version'), 'capitalize'),
          },
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <PolicyFileCreatePage />
            </OrgFeatureFlaggedRoute>
          ),
        },
      ],
    },
    ...Object.entries(IssueTypeMapping).map(([_, itm]) => ({
      path: itm.uriPath,
      handle: {
        title: () => i18n.format(i18n.t(itm.entityLabelOther), 'capitalize'),
      },
      children: [
        {
          index: true,
          element: (
            <OrgFeatureFlaggedRoute featureFlag="policy">
              <ProtectedRoute
                permission={'read:document'}
                canHaveAccessAsContributor={true}
              >
                <PolicyUpdatePage activeTabId={itm.taxonomy} />
              </ProtectedRoute>
            </OrgFeatureFlaggedRoute>
          ),
        },
        issueRoute,
      ],
    })),
    {
      path: 'approvals',
      handle: {
        title: () => i18n.t('approvals.tab_title'),
      },
      element: (
        <ProtectedRoute
          permission={'read:document'}
          canHaveAccessAsContributor={true}
        >
          <PolicyUpdatePage activeTabId="approvals" />
        </ProtectedRoute>
      ),
    },
    {
      path: 'linked-items',
      handle: {
        title: () => i18n.t('linkedItems.tab_title'),
      },
      element: (
        <ProtectedRoute
          permission={'read:document'}
          canHaveAccessAsContributor={true}
        >
          <PolicyUpdatePage activeTabId="linkedItems" />
        </ProtectedRoute>
      ),
    },
  ],
};
