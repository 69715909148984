import Alert from '@cloudscape-design/components/alert';
import Spinner from '@cloudscape-design/components/spinner';
import Button from '@risksmart-app/components/Button';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import TabHeader from '@/components/TabHeader';
import {
  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
} from '@/generated/graphql';

import { ScimConfig } from './ScimConfig';

const AuthenticationTab: FC = () => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'authenticationSettings.scimProvisioning',
  });
  const { addNotification } = useNotifications();
  const { user } = useRisksmartUser();
  const [disableScimModalVisible, setDisableScimModalVisible] = useState(false);

  const { data, loading, refetch } = useGetOrganisationQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [update] = useUpdateOrganisationMutation();

  const initialValues = data?.auth_organisation[0];
  const scimEnabled = !!initialValues?.ScimEnabled;

  const onSave = async () => {
    await update({
      variables: {
        OrgKey: user!.orgKey,
        ScimEnabled: !scimEnabled,
      },
    });
    await refetch();

    setDisableScimModalVisible(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-5">
      <TabHeader
        actions={
          scimEnabled ? (
            <Button
              onClick={() => {
                setDisableScimModalVisible(true);
              }}
            >
              {'Disable'}
            </Button>
          ) : (
            <Button variant="primary" onClick={onSave}>
              {'Enable'}
            </Button>
          )
        }
      >
        {t('header')}
      </TabHeader>
      {scimEnabled && <ScimConfig />}
      <ConfirmModal
        header={t('disableScimHeader')}
        onConfirm={onSave}
        onDismiss={() => setDisableScimModalVisible(false)}
        isVisible={disableScimModalVisible}
      >
        <Alert statusIconAriaLabel="Warning" type="error">
          {t('disableScimWarning')}
        </Alert>
      </ConfirmModal>
    </div>
  );
};

export default AuthenticationTab;
