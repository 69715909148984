import dayjs, { Dayjs } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';

import { TestFrequencyEnum, UnitOfTimeEnum } from '../../generated/graphql';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

export const getDueDate = ({
  startDate,
  frequency,
  latestDate,
}: {
  startDate?: string | null;
  frequency?: TestFrequencyEnum | null;
  latestDate?: string | null;
}): string | null => {
  if (!frequency) {
    return null;
  }

  if (!startDate || frequency === TestFrequencyEnum.Adhoc) {
    return null;
  }

  if (!latestDate || dayjs(startDate).isAfter(latestDate)) {
    return startDate;
  }
  const startDateDayjs = dayjs.utc(startDate);

  const latestDateDateJs = dayjs.utc(latestDate);

  let nextDate = latestDateDateJs
    .hour(startDateDayjs.hour())
    .minute(startDateDayjs.minute())
    .second(startDateDayjs.second())
    .millisecond(startDateDayjs.millisecond());

  switch (frequency) {
    case TestFrequencyEnum.Daily:
      break;
    case TestFrequencyEnum.Weekly:
      nextDate = nextDate.day(startDateDayjs.day());
      break;
    case TestFrequencyEnum.Fortnightly:
      {
        const remainder = startDateDayjs.diff(nextDate, 'days') % 14;
        nextDate = nextDate.add(remainder, 'days');
      }
      break;
    case TestFrequencyEnum.Fourweekly:
      {
        const remainder = startDateDayjs.diff(nextDate, 'days') % 28;
        nextDate = nextDate.add(remainder, 'days');
      }
      break;
    case TestFrequencyEnum.Monthly:
      nextDate = nextDate.date(startDateDayjs.date());
      break;
    case TestFrequencyEnum.Quarterly:
      {
        const remainder = startDateDayjs.diff(nextDate, 'month') % 3;
        nextDate = nextDate.add(remainder, 'month');
        nextDate = nextDate.date(startDateDayjs.date());
      }
      break;
    case TestFrequencyEnum.Biannually:
      {
        const remainder = startDateDayjs.diff(nextDate, 'month') % 6;
        nextDate = nextDate.add(remainder, 'month');
        nextDate = nextDate.date(startDateDayjs.date());
      }
      break;
    case TestFrequencyEnum.Annually:
      nextDate = nextDate
        .month(startDateDayjs.month())
        .date(startDateDayjs.date());
      break;

    default:
      return null;
  }

  // The process of matching day/minute etc above may have been enough to move into the next period, so ensure that's not the case before adding the next interval
  if (nextDate.isAfter(latestDate)) {
    return nextDate.utc().toISOString();
  }

  return addIntervalToDate(nextDate, frequency)?.utc().toISOString() ?? null;
};

const unitOfTimeToQUnitType: {
  [unitOfTime in UnitOfTimeEnum]: dayjs.ManipulateType;
} = {
  [UnitOfTimeEnum.Day]: 'day',
  [UnitOfTimeEnum.Week]: 'week',
};

export const getOverdueDate = ({
  nextTestDate,
  timeToCompleteUnit,
  timeToCompleteValue,
}: {
  nextTestDate: string | null | undefined;
  timeToCompleteUnit: UnitOfTimeEnum | null | undefined;
  timeToCompleteValue: number | null | undefined;
}): string | null => {
  if (!nextTestDate || !timeToCompleteUnit || !timeToCompleteValue) {
    return null;
  }
  const d = dayjs(nextTestDate);
  if (d.isValid() === false) {
    return null;
  }

  const unit = unitOfTimeToQUnitType[timeToCompleteUnit];
  if (!unit) {
    throw new Error(`Missing unit for ${timeToCompleteUnit}`);
  }

  return d.add(timeToCompleteValue, unit).toISOString();
};

export const addIntervalToDate = (
  d: Dayjs | null,
  frequency: TestFrequencyEnum | null
): Dayjs | null => {
  if (!frequency || !d) {
    return null;
  }

  if (d.isValid() === false) {
    return null;
  }

  let nextDate = null;
  switch (frequency) {
    case 'daily':
      nextDate = d.add(1, 'day');
      break;
    case 'weekly':
      nextDate = d.add(1, 'week');
      break;
    case 'fortnightly':
      nextDate = d.add(2, 'week');
      break;
    case 'fourweekly':
      nextDate = d.add(4, 'week');
      break;
    case 'monthly':
      nextDate = d.add(1, 'month');
      break;
    case 'quarterly':
      nextDate = d.add(1, 'quarter');
      break;
    case 'biannually':
      nextDate = d.add(6, 'month');
      break;
    case 'annually':
      nextDate = d.add(1, 'year');
      break;
    default:
      break;
  }

  return nextDate || null;
};
